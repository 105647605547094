.page-showproduct {

  #main-area{
    .content{

    }
  }

  .product-images {
    @media #{$medium-up}{
      float: left;
      width: 60%;
      margin: 0 0 10px 0;
      padding: 0;
    }
  }

  .product-description {
    @media #{$medium-up}{
      float: right;
      width: 40%;
      margin: 0 0 10px 0;
      clear: right;
      padding-left: 40px;
    }
  }


  .product-toolbar, .product-page-footer{
    display: none;
  }
  .product-description {
    .product-short-description{
      line-height: 1.8em;
    }
    .purchase-block-quantity span {
      display: none;
    }

    .purchase-block-quantity input {
      width: 100%;
      height: 46px;
      padding: 0;
      text-align: center;
      border: 1px solid $border-color;
    }

    .purchase-block-quantity {
      width: 15%;
      float: left;
      margin: 0 2% 15px 0;
      .quantity-button{
        cursor: pointer;
        display: inline-block;
        font-family: $font-icon;
        font-size: 10px;
        text-align: center;
        height: 45px;
        line-height: 45px;
        border: 1px solid $border-color;
        background: #F5F5F5;
        width: 30%;
        vertical-align: top;
        &.minus {
          border-right: none;
          &:before {
            content: $font-icon-minus;
          }
        }
        &.plus {
          border-left: none;
          &:before {
            content: $font-icon-plus;
          }
        }
      }
      input {
        height: 45px;
      }
    }

    .purchase-block.quantity-button-activated{
      .purchase-block-quantity{
        width: 38%;
        input{
          width: 40%;
          border: 1px solid $border-color;
        }
      }
      .purchase-block-buy {
        width: 58%;
      }
    }

    .purchase-block-buy {
      width: 83%;
      float: right;
      margin: 0 0 15px 0;
      a {
        width: 100%;
        height: 45px;
        line-height: 45px;
        padding: 0;
      }
    }
    .fancybox-iframe-stock-notification{
      height: 45px;
      line-height: 45px;
      padding: 0;
    }

    .purchase-block-price {
      .currency {
        margin-left: 5px;
      }
    }

    .purchase-block-price {
      margin-top: 10px;
    }
    
    .purchase-block-stock{
      .stock-status-label{
        //display: none;
      }
      .stock-status-value{
        margin-left: 10px;
      }
      &.in-stock{
        .stock-status-value:before {
          content: $font-icon-check;
          font-family: $font-icon;
          display: inline-block;
          color: green;
          font-size: 14px;
          margin-right: 6px;
          vertical-align: middle;
        }
      }
      .extra-info{
        display: none;
      }
    }
  }
  .product-page-back-link{
    margin: 10px 0;
  }
  .releware-recommendation-wrapper{
    margin-top: 30px;
  }
}

