.grid-row{
  margin: 0;
  overflow: hidden;  
  >*{
    float: left;
    //padding: 20px;
    text-align: center;
    ul{
      padding: 5px 0 15px 0 ;
    }
  }          
}

$grid-row-margin: 2;

@media #{$large-up} {
  @include calculate-item-width(1, $grid-row-margin, ".grid-row .col1of1");
  @include calculate-item-width(2, $grid-row-margin, ".grid-row .col1of2");
  @include calculate-item-width(3, $grid-row-margin, ".grid-row .col1of3");
  @include calculate-item-width(4, $grid-row-margin, ".grid-row .col1of4");
  @include calculate-item-width(5, $grid-row-margin, ".grid-row .col1of5");
  @include calculate-item-width(6, $grid-row-margin, ".grid-row .col1of6");          
}

@media #{$medium-only} {
  @include calculate-item-width(1, $grid-row-margin, ".grid-row .col1of1");
  @include calculate-item-width(2, $grid-row-margin, ".grid-row .col1of2");
  @include calculate-item-width(1, $grid-row-margin, ".grid-row .col1of3");
  @include calculate-item-width(2, $grid-row-margin, ".grid-row .col1of4");
  @include calculate-item-width(5, $grid-row-margin, ".grid-row .col1of5");
  @include calculate-item-width(3, $grid-row-margin, ".grid-row .col1of6");
  .grid-row{
    >*{
      margin-bottom: 2%;
    }
  }            
}

@media #{$small-only} {
  @include calculate-item-width(1, $grid-row-margin, ".grid-row .col1of1");
  @include calculate-item-width(2, $grid-row-margin, ".grid-row .col1of2");
  @include calculate-item-width(1, $grid-row-margin, ".grid-row .col1of3");
  @include calculate-item-width(2, $grid-row-margin, ".grid-row .col1of4");
  @include calculate-item-width(1, $grid-row-margin, ".grid-row .col1of5");
  @include calculate-item-width(2, $grid-row-margin, ".grid-row .col1of6");
  .grid-row{
    >*{
      margin-bottom: 2%;
    }
  }            
}

