//
//  Header
//

#header {
  clear: both;
  position: relative;
  width: 100%;
  margin: 0;
  background-color: $js-header-background-color;
  @include clearfix();
}

#header-inner {
  max-width: $js-max-page-width;
  margin: 0 auto;
  @include clearfix();
}


.logo-wrapper {
  float: left;
  margin: $js-logo-wrapper-margin;

  .logo-header {
    display: none;
  }

  .logo-body {
    max-width: $js-logo-wrapper-max-width;
    line-height: 0;
  }

  input {
    outline: none;
    max-width: 100%;
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    border-radius: 0 !important;
  }

  .logo-footer {
    display: none;
  }
}

.header-scrolled .logo-wrapper {
}

//
//  Sub Navigation / Breadcrumbs
//
#path-nav {
  display: block;
  left: 0;
  width: 100%;
  max-width: $js-max-page-width;
  padding-left: $js-page-padding-left;
  padding-right: $js-page-padding-right;
  padding-top: 10px;
  padding-bottom: 2px;
  font-size: $js-breadcrumb-font-size;
  margin: 0 auto;

  .breadcrumb-link {
    color: $js-breadcrumb-font-color;
    display: inline-block;
  }

  .breadcrumb-spacer {
    color: $js-primary-link-color;
  }
}

//
//  Search Box
//

.search-box-wrapper {
  font-size: 14px;
  padding: $js-search-box-wrapper-padding;
  margin: $js-search-box-wrapper-margin;
  float: left;
  background-color: $js-search-box-wrapper-background-color;
}

.search-box-header {
  display: none;
}

.search-box-body {
  position: relative;
  overflow: hidden;
  height: auto;
  line-height: 32px;
}

.search-box-input {
  float: left;
}

.search-box-input input {
  border: 1px solid $primary-color;
  width: $js-search-box-input-width;
  background-color: $js-search-box-input-background-color;
  padding: $js-search-box-input-padding;
  font-size: $js-search-box-input-font-size;
  outline: none;
  color: $js-search-box-input-color;
  @include border-radius(0);
  -webkit-appearance: none;
  display: block;
}

.search-box-input input:focus {
  background: $js-search-box-input-background-color-focus;
  color: $js-body-font-color;
}

.search-box-button {
  float: left;
  margin-top: -2px;
  position: absolute;
  right: 6px;
  a {
    padding: 0;
    &:focus {
      outline: none;
    }
  }
  img {
    width: 21px;
  }
}

/* MARQUEE
----------------------------------------------------------------------------- */
.marquee {
  margin: 0;
  display: none;
}

//
//    MEDIUM DOWN
//
@media #{$medium-down} {
  .vat-selector-wrapper .vat-selector-label span {
    font-size: 1em;
  }
  .logo-wrapper {
    margin: $js-logo-wrapper-margin-medium;
  }
}


//
//  Small
//
@media #{$small-only} {
  .logo-wrapper {
    float: none;
    display: inline-block;
    width: 100%;
    margin: $js-logo-wrapper-margin-small;
    .logo-body {
      margin: 0 auto;
    }
  }
}