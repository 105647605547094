
//
//  My pages
//
html.page-mypages {
  div.ContentAreaBodyMyPages {
    font-size: 12px;
  }
  #sub-nav-wrapper{
    display: none;
  }
  #main-area{
    width: 100%;
    .content{
      max-width: 800px;
      margin: auto;
    }
  }
  #right-area {
    display: none;
  }
  .LoadCustomerProfileForm{
    input{
      width: 180px;
      height: 30px;
      padding: 0 5px;
      margin-top: 10px;
    }
  }
  .CustomerProfileBtn{
    width: 180px;
    .button{
      padding: 0;
      height: 40px;
      line-height: 40px;
      margin-top: 10px;
    }
  }

  td.LoadCustomerProfileSelector span.Checkout {
    margin-right: 8px;
    display: inline-block;
  }

  td.CreateCustomerProfileSelector span.Checkout {
    margin-right: 8px;
    display: inline-block;
  }

  td.CustomerProfileLabel span.Checkout {
    margin-right: 8px;
    display: inline-block;
  }

  .Checkout.CheckoutInputRadio {
    margin-right: 9px;
    display: inline-block;
  }

  .Checkout.CheckoutInputTextSmall + span {
    margin-left: 5px;
    display: inline-block;
    color: $alert-color;
    font-weight: 700;
  }

  .Checkout.CheckoutInputTextBig label{
    margin-left: 6px;
  }

  .Checkout.CheckoutInputTextBig + span {
    margin-left: 5px;
    display: inline-block;
    color: $alert-color;
    font-weight: 700;
  }
  .ajax__tab_header{
    background: none;
    font-family: $font-family-sans-serif;
    .ajax__tab_outer{
      height: auto;
      cursor: pointer;
      margin: 0 3px 0 0;
      padding: 0.5rem 1rem;
      border: 1px solid darken(#EEE, 10%);
      border-bottom: none;
      width: auto;
      margin-right: 1%;
      padding: 0.5rem 0.3rem;
      border-radius: 0;
      .ajax__tab_inner{
        width: 100%;
        background: none;
        .ajax__tab_tab{
          background: none;
          border: none;
          width: 100%;
          padding: 0 !important;
          font-size: 10px !important;
          @media #{$medium-up}{
            width: auto;
            padding: 0.5rem 1rem;
            font-size: 13px !important;
          }
        }
      }
      @media #{$medium-up}{
        width: auto;
        padding: 0.5rem 1rem;
        font-size: 14px;
      }
    }
    .ajax__tab_active{
      .ajax__tab_outer{
        background: #555;
        border: 1px solid #555;
        color: white;
        position: relative;
        width: auto;
        a span{
          color: white;
        }
        &:after{
          font-family: FontAwesome;
          content: "\f0d7";
          position: absolute;
          left: 46%;
          font-size: 18px;
          bottom: -18px;
          color: #555;
        }
      }
    }
  }
  .ajax__tab_body{
    padding: 0.5rem 0.5rem;
    border: 1px solid $border-color !important;
    background: #FFF;
    border-radius: 0;
    //border: 1px solid $border-color;
    @media #{$medium-up}{
      padding: 1rem;
      //border: 1px solid $border-color;
    }
  }

  //REGISTER
  table.CustomerProfile{
    tr{
      height: 20px;
      input[type=text], [type=password]{
        height: 26px;
        padding: 0 5px;
      }
      input[type=checkbox]{
        margin-right: 5px;
        position: relative;
        top: 2px;
      }
    }
  }
}

#ctl00_ctl00_main_rightmain_myPagesControl_myPagesTabContainer_tabPanelCustomerAddresses_myCustomerInfo_cii_sem {
  background-color: #eeeeee;
}

.MyPagesSettingsMessageToShopSubjectCssClass input {
  max-width: 200px;
}

.MyPagesSettingsMessageToShopContentCssClass textarea {
  max-width: 200px;
}

.MyPagesSettingsNewsLetterCssClass input {
  margin-right: 8px;
}
