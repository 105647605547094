#sub-nav-wrapper{
	background: white;//transparent;
	padding-top: 7px;
	padding-bottom: 7px;
    padding-left: $js-main-area-padding-left;
    padding-right: $js-main-area-padding-right;
    //border-bottom: 1px solid #f1f1f1;
	a, span{
		color: #333;
		font-size: 11px;
		font-weight: normal;
	}
	a.home-link{
		font-size: 0;
		font-weight: normal;
		&:before{
			font-size: 16px;
			font-family: $font-icon;
			content: $font-icon-home;
			margin: 1px 2px 0 0;
		}
	}
	span.breadcrumb-spacer{
		margin: 0 5px;
	}
}