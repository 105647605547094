

//LEFT-NAV
$left-nav-width:           	280px;
$left-nav-padding-left:    	5%;
$left-nav-padding-right:   	5%;
$left-nav-toggle-font-size:    16px;
$left-nav-logo-max-height:		16px;

$left-nav-icon-width:			10%;
$left-nav-text-width:			90%;

$left-nav-background:      	white;//$secondary-color;
$left-nav-cat-background:  	transparent;
$left-nav-border:          	$border-color;
$left-nav-color:           	$js-body-font-color;
$left-nav-toggle-color:    	#777;
$left-nav-selected:        	$js-buy-trail-color;
$left-nav-icon-selected:   	$left-nav-color;
$left-nav-show-all-link:	darken($secondary-color, 10%);
$left-nav-icon-padding-right:  5px;

$left-nav-level1-background:	$left-nav-background;
$left-nav-level2-background:	$left-nav-background;//lighten($left-nav-level1-background, 4%);
$left-nav-level3-background:	$left-nav-background;//lighten($left-nav-level2-background, 4%);
$left-nav-level4-background:	$left-nav-background;//lighten($left-nav-level3-background, 4%);

$left-nav-level1-color:		$js-body-font-color;
$left-nav-level2-color:		$js-body-font-color;
$left-nav-level3-color:		$js-body-font-color;
$left-nav-level4-color:		$js-body-font-color;

$left-nav-level1-border:		1px solid #EEE;
$left-nav-level2-border:		1px solid #EEE;
$left-nav-level3-border:		1px solid #EEE;
$left-nav-level4-border:		1px solid #EEE;


$left-nav-level1-font-weight:  bold;
$left-nav-level2-font-weight:  bold;
$left-nav-level3-font-weight:  normal;
$left-nav-level4-font-weight:  normal;

$left-nav-icon-font-size: 		20px;

$left-nav-level1-font-size:	16px;
$left-nav-level2-font-size:	12px;
$left-nav-level3-font-size:	12px;
$left-nav-level4-font-size:	12px;

$left-nav-level1-height:     	34px;
$left-nav-level2-height:    	32px;
$left-nav-level3-height:    	30px;
$left-nav-level4-height:    	30px;

$left-nav-level1-padding-left:	0%;
$left-nav-level2-padding-left:	8%;
$left-nav-level3-padding-left:	13%;
$left-nav-level4-padding-left:	18%;
$left-nav-level5-padding-left: 22%;

$left-nav-level1-padding-right: 0%;
$left-nav-level2-padding-right: 0%;
$left-nav-level3-padding-right: 0%;
$left-nav-level4-padding-right: 0%;

//PAGE LIST IN MOBILE SIDE NAV
$left-nav-page-list-border-color:	#EEE;
$left-nav-page-list-color:     		$js-body-font-color;
$left-nav-page-list-line-height:	46px;
$left-nav-page-list-font-size: 		14px;