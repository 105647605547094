
//TOP BAR
#top-bar{
	display: none;
	@media #{$large-up}{
		@if $top-bar{
			display: block;
			position: absolute;
			@if $header-is-fixed{
				position: fixed;
			}
			width: 100%;
			top: 0;
			height: $top-bar-height;
			background: $top-bar-background;
			color: $top-bar-color;
			z-index: 95;
			.inner{
		  		max-width: 1410px;//$js-max-page-width;
		  		margin: 0 auto;
			}
			.page-list{
				float: left;
				width: 50%;
				padding: 0 1px;
				a{
					line-height: $top-bar-height;
					color: $top-bar-color;
					float: left;
					display: block;
					margin-right: 10px;
					font-size: $top-bar-font-size;
					&:hover{
						color: darken($top-bar-color, 10%);
					}
				}
				div:last-child >a{
					margin-right: 0;
				}
			}
			.vat-selector-wrapper{
				display: none;
			}
			.InfoTextWrapper{
				//display: none;
				ul{
					float: right;
					text-align: right;
					li{
						margin-right: 5px;
						display: inline-block;
						line-height: $top-bar-height;
						font-size: 12px; //$top-bar-font-size;
						&:last-child{
							margin-right: 0;
						}
						&:before{
							font-family: $font-icon;
							content: $font-icon-check;
							font-size: 12px;
							margin-right: 5px;
							vertical-align: top;
						}
						&:last-child{
							margin-right: 0;
						}
					}
				}
				ul.contact{
					float: right;
					li{

					}
				}
			}
		}
	}	
}

.phone:before, .address:before{
	font-family: $font-icon;
	font-size: 16px !important;
}
.phone:before{
	content: $font-icon-phone !important;
}
.address:before{
	content: $font-icon-position !important;
}