$attribute-wrapper-background:            white;

$attribute-button-background:             #333;
$attribute-button-color:                  white;
$attribute-button-border:                 2px solid #222;

$attribute-button-selected-background:    $js-buy-trail-color;
$attribute-button-selected-color:         white;
$attribute-button-selected-border:        2px solid #222;

$attribute-button-attr2-background:       $attribute-button-background;
$attribute-button-attr2-color:            $attribute-button-color;
$attribute-button-attr2-border:           $attribute-button-border;

$attribute-button-disabled-background:    #BBB;
$attribute-button-disabled-color:         white;
$attribute-button-disabled-border:        2px solid #AAA;

$attribute-button-disabled-selected-background:    lighten($js-buy-trail-color, 25%);
$attribute-button-disabled-selected-color:         white;
$attribute-button-disabled-selected-border:        2px solid #AAA;
