@import "left-nav-settings";


//LEFT NAV
#left-area .nav {
	margin-top: 8px;
	background: $left-nav-background;
	border: 1px solid $border-color;
	margin-bottom: 5px;
	//box-shadow: 1px 1px 3px #eeeeee;
	ul{
		list-style-type: none;
		margin: 0;
		padding: 0;
	}




	//LEFT NAV
	ul.category-navigation{
		padding: 5px $left-nav-padding-right 5px $left-nav-padding-left;
		overflow: hidden;
		clear: both;
		//GENERAL
		li{
			clear: both;
			padding: 0;
			overflow: hidden;
			a, span{
				display: block;
				padding: 0 $left-nav-padding-right 0 0;
				color: $left-nav-color;
			}
			>.icon{
				width: $left-nav-icon-width;
				float: right;
				padding: 0;
				border-right: none;
			}
			a{
				width: $left-nav-text-width;
				float: left;
				text-align: left;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				&.icon{
					&:before{
						padding-right: $left-nav-icon-padding-right;
						font-size: $left-nav-icon-font-size;
						color: #000;
						font-family: $font-icon;
						content: $font-icon-plus;
						font-weight: normal !important;
						display: block;
						text-align: right;
					}
				}
				&:hover{
					color: $left-nav-selected;
					&:before{
						color: $left-nav-selected;
					}
				}
			}
			ul{
				clear: both;
			}
			&.has-subcategories{
				>ul>li{
					display: none;
				}
			}
			&.active{
				>ul>li{
					display: block;
				}
				&.selected, &:not(.open){
					>a:not(.icon){
						color: $left-nav-selected;
						font-weight: bold;
						/*&:after{
							font-family: "FontAwesome";
							content: "\f053";
							font-size: 12px;
							margin-left: 5px;
							color: $left-nav-icon-selected;
							position:relative;
							top: -1px;
						}*/
					}
				}
			}
			&.open{
				>a.icon{
					&:before{
						content: $font-icon-minus;
					}
				}
			}
			&.open>a{
				//border-bottom: $left-nav-level1-border;
			}
			&:first-child{

			}
			&:last-child{
				border-bottom: none;
			}
			&.show-all-link a{
				color: $left-nav-show-all-link !important;
			}
		}
		//LEVEL 1
		>li{
			padding-right: $left-nav-level1-padding-right;
			background: $left-nav-level1-background;
			border-bottom: $left-nav-level1-border !important;
			>a:not(.icon){
				font-weight: $left-nav-level1-font-weight;
				padding-left: $left-nav-level1-padding-left;
			}
			>a{
	 			font-size: $left-nav-level1-font-size;
				height: $left-nav-level1-height;
				line-height: $left-nav-level1-height;
				color: $left-nav-level1-color;
			}
			&.open{
				width: 100%; //100% - $left-nav-padding-left - $left-nav-padding-right;
			}
			//Level2
			>ul>li{
				padding-right: $left-nav-level2-padding-right;
				background: $left-nav-level2-background;
				border-bottom: $left-nav-level2-border;
				>a:not(.icon){
					font-weight: $left-nav-level2-font-weight;
					padding-left: $left-nav-level2-padding-left;
				}
				>a{
					font-size: $left-nav-level2-font-size;
					height: $left-nav-level2-height;
					line-height: $left-nav-level2-height;
					color: $left-nav-level2-color;
				}
				&.open{
					//border-bottom: $left-nav-level1-border;
				}
			}

			//Level3
			>ul>li>ul>li{
				padding-right: $left-nav-level3-padding-right;
				background: $left-nav-level3-background;
				border-bottom: $left-nav-level3-border;
				>a:not(.icon){
					font-weight: $left-nav-level3-font-weight;
					padding-left: $left-nav-level3-padding-left;
				}
				>a{
					font-size: $left-nav-level3-font-size;
					height: $left-nav-level3-height;
					line-height: $left-nav-level3-height;
					color: $left-nav-level3-color;
				}

			}

			//Level4
			>ul>li>ul>li>ul>li{
				padding-right: $left-nav-level4-padding-right;
				background: $left-nav-level4-background;
				border-bottom:$left-nav-level4-border;
				>a:not(.icon){
					font-weight: $left-nav-level4-font-weight;
					padding-left: $left-nav-level4-padding-left;
				}
				>a{
					font-size: $left-nav-level4-font-size;
					height: $left-nav-level4-height;
					line-height: $left-nav-level4-height;
					color: $left-nav-level4-color;
				}
			}
			//level5
			>ul>li>ul>li>ul>li>ul>li{
				padding-right: $left-nav-level4-padding-right;
				background: $left-nav-level4-background;
				border-bottom:$left-nav-level4-border;
				>a:not(.icon){
					font-weight: $left-nav-level4-font-weight;
					padding-left: $left-nav-level5-padding-left;
				}
				>a{
					font-size: $left-nav-level4-font-size;
					height: $left-nav-level4-height;
					line-height: $left-nav-level4-height;
					color: $left-nav-level4-color;
				}
			}

		}
	}

}

#left-area .category-navigation{
	li.lv6{
		display: none !important;
	}
	li.lv3{
		.icon{
			display: inline-block;
		}
	}
}


#left-area{
	//PAGE LIST
	.page-list{
		padding-top: 21px;
		padding-bottom: 10px;
		>*{
			display: block;
			margin-left: $left-nav-padding-left;
			margin-right: $left-nav-padding-right;
			color: $left-nav-page-list-color;
			line-height: 30px; //$left-nav-page-list-line-height;
			font-size: $left-nav-page-list-font-size;
			border-bottom: 1px solid $left-nav-page-list-border-color;
			&:before{

			}
			&:hover{
				color: $left-nav-page-list-color;
			}
			&.my-pages:before{
				//font-family: $font-icon;
				//content: "\f007";
				//margin-right: 5px;
			}
		}
	}
}

/// left nav banner

.left-nav-banner-wrapper{
	display: none;
}


#left-area .left-nav-banner-wrapper{
	background: white;
	display: block;
	.left-nav-banner{
		padding: 2%;
		img{
			padding: 2%;
		}
	}
}

//// Left display area
.InfoTextWrapper{
	.left-display-area{
		display: none;
	}
}

.left-display-area{
	display: inline-block;
	text-align: center;
	.left-social{
		display: inline-block;
		font-size: 0;
		width: 100%;
		text-align: center;
		margin: 20px 0;
		border-bottom: 3px solid $border-color;
		padding-bottom: 15px;
		>a{
			display: inline-block;
			width: 25%;
		}
		.facebook:before{
			font-family: "FontAwesome";
			content: $font-icon-facebook;
			font-size: 30px;
		}
		.twitter:before{
			font-family: "FontAwesome";
			content: $font-icon-twitter;
			font-size: 30px;
		}
		.youtube:before{
			font-family: "FontAwesome";
			content: $font-icon-youtube;
			font-size: 30px;
		}
	}
	.left-brand{
		display: inline-block;
		img{
			margin: 15px 0;
		}
	}
}

.page-mypages{
	#left-area{
		display: none;
	}
}