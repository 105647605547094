@import "dynamic-cart-settings";

//DYNAMIC CART DROPDOWN MODE
#dc-wrapper.dropdown {
  color: $js-body-font-color;
  line-height: 1.2;
  display: none;
  position: fixed;
  top:  $top-bar-height + $header-height-desktop;
  right: 0;
  background: #fff;
  color: $js-body-font-color;
  line-height: 1.2;
  border: 1px solid $border-color;
  box-sizing: padding-box;
  z-index: 101;
  width: $js-dc-dropdown-width-large-up;
  padding: $js-dc-dropdown-wrapper-padding;
  max-width: 100%;
  @media all and (min-width: $js-max-page-width) {
    right: calc(50% - #{$js-max-page-width/2});
  }
  @media #{$medium-down}{
    top: $header-height;
  }
  .cart-arrow{
    right: 9px;
    @media #{$large-up}{
      right: 10%;
    }
  }
  #dc-inner {
    @include clearfix();
  }
  #dc-header{
    display: none;
  }
  #dc-loader-msg {
    max-height: 0;
    padding: 0;
    transition: 0.3s;
    overflow: hidden;
    font-weight: bold;
    font-size: 1.1em;
    text-align: center;
    &::before {
      content: url("../images/ajax-loader-big.gif");
      display: inline-block;
      vertical-align: middle;
    }
    span {
      display: inline-block;
      vertical-align: middle;
      margin: 0 0 0 10px;
    }
  }

  &.loading #dc-loader-msg {
    max-height: 4em;
    padding: 10px;
  }
  #dc-empty-msg{
    display: none;
  }
  &.empty{
    #dc-empty-msg{
      display: block;
      text-align: center;
      padding: 10px 0;
      h3{
        font-size: 1.1em;
        text-transform: none;
      }
    }
    #dc-body, #dc-header, #dc-btns{
      display: none;
    }
  }
  #dc-body {

  }

  #dc-items-header {
    > div {
      color: #666;
      font-size: $cart-header-font-size;
      font-weight: $cart-header-font-weight;
      min-height: 2em;
      float: left;
      padding: 6px;
    }
    .price {
      text-align: center;
    }
    .total {
      display: none;
      text-align: right;
      padding-right: 10px;
    }

  }

  #dc-content {
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    @media #{$small-only}{
      max-height: 183px;
    }
    > div {
      @include clearfix();
      transition: opacity 0.3s;
      clear: both;
      > div {
        float: left;
        padding: 5px;
      }
    }

    //ROW
    .dc-item-row {
      border-bottom: 1px solid $border-color;
      padding: 15px 0;
      font-size: $cart-row-font-size;
      font-weight: $cart-row-font-weight;
      position: relative;
      @keyframes animatedBackgroundCart {
        from {
          background-color: lighten($primary-color, 35%);
        }
        to {
          background-color: #fff
        }
      }
      &.product-added {
        animation: animatedBackgroundCart 0.8s linear 1;
      }
      &.deleting {
        opacity: 0;
      }
    }
    .item {
      padding-left: 10px !important;
      .img {
        padding-left: 0;
        padding-right: 0;
        background-color: white;
        width: 30%;
        float: left;
        padding-right: 10px;
        a {
          overflow: hidden;
          display: block;
          position: relative;
          padding-top: $js-product-wrapper-image-wrapper-height;
          img {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            max-height: 100%;
            max-width: 100%;
            margin: auto;
          }
        }
      }
      .description {
        width: 70%;
        float: left;
        .name {
        }
        .item-number {
          display: none;
          font-size: 0.8rem;
          padding: 3px 0 0;
        }
        .comments {
          font-style: italic;
        }
      }
    }
    .price {
      text-align: center;
      white-space: nowrap;
      .separator, .qty-suffix{
        display: none;
      }
    }
    .qty {
      text-align: center;
      .label {
        display: none;
      }
      .value{

      }
    }
    .dynamic-cart-icon{
      display: none;
      width: 35%;
      text-align: center;
      padding: 0;
      cursor: pointer;
      //float: left;
      border: 1px solid darken($cart-qty-changer-background, 5%);
      background: $cart-qty-changer-background;
      color: $cart-qty-changer-color;
      &:last-child{
        margin-right: 0;
      }
      &:before{
        font-family: $font-icon;
        font-size: 12px;
      }
      &:hover{
        border: 1px solid darken($cart-qty-changer-background, 10%);
        background: darken($cart-qty-changer-background, 5%);
      }
    }
    .minus{
      &:before{
        content: $font-icon-minus;
      }
    }
    .plus{
      &:before{
        content: $font-icon-plus;
      }
    }
    .delete {
      opacity: 0.3;
      position: relative;
      top: -2px;
      &:after{
        font-family: $font-icon;
        content: $font-icon-delete;
        font-size: 18px;
        cursor: pointer;
        line-height: 1;
      }
      &:hover {
        opacity: 1;
      }
    }
    .total {
      text-align: right;
      white-space: nowrap;
      padding-right: 10px;
    }
  }

  //TOTALS
  #dc-totals {
    text-align: center;
    padding: 30px 0 15px 0;
    span {
      display: block;
    }
    .label{
      font-size: $cart-sum-font-size;
      font-weight: $cart-sum-font-weight;
    }
    .value {
      font-weight: bold;
      transition: 0.3s;
      margin: 10px 0 20px;
      font-size: $cart-total-font-size;
      font-weight: $cart-total-font-weight;
    }

    .leasing span {

        color: #1E5BA2;
        font-weight: normal;
        font-family: IntroCondBold;
        font-size: 14px;
        line-height: 21px;

      &.value {
        font-size: 18px;
        font-weight: bold;
      }
    }


    .vat-total {
      display: none;
      span {
        font-size: 1em;
        color: #666;
        font-weight: normal;
      }
      .value {
        padding-right: 3px;
      }
    }
  }

  &.deleting #dc-totals .value {
    opacity: 0;
  }

  //BUTTONS
  #dc-btns {
    padding: 15px 0 0 0;
    .button {
      float: none;
      margin: 0 0 0 0;
      width: 100%;
      padding: 1rem 0;
      &#dc-continue-btn{
        display: none;
      }
      &#dc-checkout-btn {
        background: $js-button-buy-background-color;
        &:hover {
          background: darken($js-button-buy-background-color, $js-checkout-form-button-factor);
        }
      }
    }
  }

  #dc-header, #dc-items-header, .dc-item-row, #dc-totals, #dc-btns {
    //max-height: 0;
    transition: 0.3s;
    overflow: hidden;
  }
  &.dropdown-open {
    max-height: none;
    z-index: 20;
    opacity: 1;
    #dc-header, #dc-items-header, .dc-item-row, #dc-totals, #dc-btns {
      max-height: 80px;
      transition: 0.3s;
    }

    #dc-inner {
      //padding-top: 0;
      //padding-bottom: 0;
    }
  }
  #dc-inner {

  }
  h3 {
    font-size: 1.2em;
    margin: 0 0 0.5em;
  }
  .item {
    width: 60%;
  }
  .qty {
    width: 10%;
  }
  .price {
    width: 20%;
  }
  .delete {
    width: 10%;
  }
  .total {
    display: none;
  }
}

html.cart-detailed{
  #dc-wrapper.dropdown{
    .cart-arrow {
      @media #{$large-up}{
        right: 147px;
      }
    }
  }
}

html.cart-icon{
  #dc-wrapper.dropdown {
    #FreeFreight_Cart {
      font-size: 12px;
    }
  }
}

html.dc-open.dynamic-cart-dropdown{
  #dc-wrapper.dropdown{
    display: block;
  }
  #side-menu-overlay{
    background: transparent;
  }
}

@if $header-is-fixed{

}@else{
  html.scrolling.dc-open.dynamic-cart-dropdown {
    #dc-wrapper {
      top: 0;
    }
  }
}