#other-colors{
	display: inline-block;
	margin-bottom: 15px;
	h5{
		font-size: 1.1em;
		/*text-align: center;
		font-size: 16px;
		padding-bottom: 5px;
		margin-bottom: 10px;
		border-bottom: 1px solid $border-color;*/
	}
	ul{
		li{
			margin-bottom: 2px;
			.product-wrapper{
				background: #357c32 !important;
				.product-image{
					display: none;
					margin-bottom: 0;
				}
				.product-info{
					.product-name{
						a{
							color: white !important;
						}
						>h3{
							display: none;
						}

					}
				}
				.product-price{
					display: none;
				}
				.product-buttons{
					display: none;
				}
			}
		}
	}

	li.ordinarie{
		.product-wrapper{
			background: $festool-blue !important;
		}
	}
}


//THIS IS THE ELEMENT THAT STARTS THE FUNKTION

.start-modeller{
	display: none;
}


//IF NOT USING SLICK SLIDER ON RELATED UNCOMMENT MEDIAQUERIES


//
//  Medium
//
@media #{$small-only} {
  @include calculate-item-width($js-total-products-small, $js-product-wrapper-margin-right, ".list-category-placeholder ul li");
}

//
//  Medium
//
@media #{$medium-only} {
  @include calculate-item-width($js-total-products-medium, $js-product-wrapper-margin-right, ".list-category-placeholder ul li");
}

//
//  Large
//
@media #{$large-only} {
  @include calculate-item-width(3, $js-product-wrapper-margin-right-large-up, ".list-category-placeholder ul li");
}

//
//  X-Large
//
@media #{$xlarge-only} {
  @include calculate-item-width(5, $js-product-wrapper-margin-right-large-up, ".list-category-placeholder ul li");
}

//
//  XX-Large
//
@media #{$xxlarge-only} {
  @include calculate-item-width(5, $js-product-wrapper-margin-right-large-up, ".list-category-placeholder ul li");
}

