//RADIO1
#attribute-select[data-type="radio1"]{
  border: 1px solid #DDD;
  background: #F2F2F2;
  padding: 20px;
  >div{
    .title{
      .text{
        font-weight: bold;
      }
      .choose-text{
        font-style: italic;
        &:before{
          content: " (";
        }
        &:after{
          content: ")";
        }
        &.select-error{
          color: red;
        }
      }
    }
    .item-wrapper{
      margin: 7px 0;
      .item{
        >span{
          display: inline-block;
        }
        .check-box{
          width: 16px;
          height: 16px;
          border: 1px solid #CCC;
          background: white;
          margin-right: 5px;
          text-align: center;
          line-height: 16px;
          vertical-align: text-bottom;
          position: relative;
          top: -1px;
          border-radius: 50%;
        }
        .value{
          color: #333;
        }
        .stock{
          &:before{
            content: " - ";
          }
        }
        .price{
          color: #333;
          margin-left: 5px;
          .previous{
            display: none;
            margin-left: 5px;
            text-decoration: line-through;
          }
        }
        &.selected{
          .check-box{
            &:before{
              content: " ";
              display: inline-block;
              margin: 0 auto;
              width: 8px;
              height: 8px;
              position: relative;
              background: #AAA;
              border-radius: 50%;
              top: -1px;
              border: 1px solid #168a0e;
            }
          }
        }
      }
      .attributes-2-inner{
        display: none;
        .item{

        }
      }
    }
  }
  .attributes-2{
    margin-top: 15px;
    .attributes-2-inner{
      display: block;
    }
  }
}