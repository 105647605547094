.list-category-placeholder{
	padding: 10px 0 0 0;
	header{
		padding-bottom: 1px;
		margin-bottom: 15px;
		border-bottom: 1px solid $border-color;
		overflow: hidden;
		>h2{
			line-height: 1.4em;
			font-size: 1.4em;
			text-align: left;
			float: left;
		}
		>a{
			float: right;
			padding-top: 6px;
			color: $secondary-color;
		}
	}
	ul{
		margin: 0;
		li{
			margin: 0 15px !important;
			.product-image{
				padding-top: $js-product-wrapper-image-wrapper-height;
			}
		}
	}
	.slick-slider{
		.slick-prev{
			left: -4px;
		}
		.slick-next{
			right: 0px;
		}
	}
}

.product-wrapper{
	.product-list-description{
        max-height: 95px;
        position:relative;
        overflow: hidden;
        &:before {
          content:'';
          width:100%;
          height:100%;    
          position:absolute;
          left:0;
          top:0;
          background:linear-gradient(rgba(255,255,255,0) 80px, rgba(255,255,255,1));
        }
    } 
}


//IF NOT USING SLICK SLIDER ON RELATED UNCOMMENT MEDIAQUERIES


//
//  Medium
//
@media #{$small-only} {
  @include calculate-item-width($js-total-products-small, $js-product-wrapper-margin-right, ".list-category-placeholder ul li");
}

//
//  Medium
//
@media #{$medium-only} {
  @include calculate-item-width($js-total-products-medium, $js-product-wrapper-margin-right, ".list-category-placeholder ul li");
}

//
//  Large
//
@media #{$large-only} {
  @include calculate-item-width(3, $js-product-wrapper-margin-right-large-up, ".list-category-placeholder ul li");
}

//
//  X-Large
//
@media #{$xlarge-only} {
  @include calculate-item-width(5, $js-product-wrapper-margin-right-large-up, ".list-category-placeholder ul li");
}

//
//  XX-Large
//
@media #{$xxlarge-only} {
  @include calculate-item-width(5, $js-product-wrapper-margin-right-large-up, ".list-category-placeholder ul li");
}

