#bodyFastLoginBox
{
    background: white;
    margin: 0;
    background-image: none;
    color: $js-body-font-color;
    font-family: $font-family-sans-serif;
    min-width: 200px !important;

    div.fast-login-box-inner {
        width: 260px !important;
    }

    .fast-login-box 
    {
        background: #333;
        margin:0;
        background-image: none;
        color: #fff;
    }

    .fast-login-box-inner
    {
        width: 400px;
        margin:0 auto;
        padding: 20px 0;
    }

    .fast-login-box-inner-header
    {
        font-size:22px;
        font-weight:bold;
        display:block;
        margin-bottom:10px;
        text-transform:uppercase;
    }

    div.fast-login-box-inner input[type=text], div.fast-login-box-inner input[type=password]{
        width: 100% !important;
        padding: 5px;
        border: 1px solid #DDD;
        height: 40px;
    }
    .fast-login-box-inner-reminder {
        color: $js-body-font-color;
    }

    .button.aForgotPassword, .button.aBackToLogin{
        background: white;
        color: #333;
        display: block;
    }

    .btnLoadProfile-wrapper, .btnForgotPassword-wrapper{
      float: none;
      .button{
          padding: 0;
          height: 40px;
          line-height: 40px;
          margin-top: 15px;
          display: block;
      }
    }



}