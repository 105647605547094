
//DYNAMIC CART RIGHTY MODE
#dc-wrapper.righty {
  width: $cart-width-small;
  max-width: 85%;
  ///border: 1px solid $cart-border;
  background: none repeat scroll 0 0 $cart-background;
  padding: 0;
  color: $cart-color;
  position: fixed;
  top: 0;// $header-height;
  height: 100%;
  z-index: 9999;
  right: -$cart-width-small;
  -webkit-transition: right .3s;
  -moz-transition: right .3s;
  -ms-transition: right .3s;
  -o-transition: right .3s;
  transition: right .3s;    
  color: $js-body-font-color;
  line-height: 1.2;
  @media #{$medium-only}{
    width: $cart-width-medium;
    right: -$cart-width-medium;
  }
  @media #{$large-up}{
    width: $cart-width-large;
    right: -$cart-width-large;           
  }
  @media all and (min-width: $js-max-page-width) {
    right: calc(50% - #{($js-max-page-width/2 + $cart-width-large)});
  }     
  #dc-inner {
    @include clearfix();
  }
  #dc-header {
    margin: 0;
    padding: 5px $cart-side-padding;
    overflow: hidden;
    border-bottom: 1px solid darken($cart-item-border-color, 10%);    
  }
  #dc-loader-msg {
    max-height: 0;
    padding: 0;
    transition: 0.3s;
    overflow: hidden;
    font-weight: normal;
    font-size: 1em;
    text-align: center;
    &::before {
      content: url("../images/ajax-loader-big.gif");
      display: inline-block;
      vertical-align: middle;
    }
    span {
      display: inline-block;
      vertical-align: middle;
      margin: 0 0 0 10px;
    }
  }
  &.loading #dc-loader-msg {
    max-height: 4em;
    padding: 10px;
  }
  #dc-empty-msg{
    display: none;
  }
  &.empty{
    #dc-empty-msg{
      display: block;
      padding: 10px $cart-side-padding;
      h3{
        font-size: 0.85rem;
        text-transform: none;
      }
    }
    #dc-body, #dc-header{
      display: none;
    } 
  }

  #dc-body {
    height: 100%;
    width: 100%;
    padding-bottom: 120px;
    position: absolute;    
  }

  #dc-items-header {
    display: none;
    > div {
      background: #ddd;
      color: #666;
      font-weight: bold;
      min-height: 2em;
      float: left;
      padding: 6px;
    }
    .price {
      text-align: center;
    }
    .total {
      text-align: right;
      padding-right: 10px;
    }

  }

  #dc-content {
    max-height: 90%;
    overflow: auto;
    > div {
      @include clearfix();
      transition: opacity 0.3s;
      clear: both;
      > div {
        float: left;
      }
    }
    .dc-item-row {
        position: relative;
        padding: 10px $cart-side-padding;
        border-top: 1px solid $cart-item-border-color;
        @keyframes animatedBackgroundCart {
          from {
            background-color: lighten($primary-color, 35%);
          }
          to {
            background-color: #fff
          }
        }
        &.product-added {
          animation: animatedBackgroundCart 0.8s linear 1;
        }
        &.deleting {
          opacity: 0;
        }
        &:first-child{
          border-top: none;
        }
    }
    .img {
      padding-left: 0;
      padding-right: 0;
      background-color: white;
      float:left;
      width: $cart-img-width;
      a {
        overflow: hidden;
        display: block;
        position: relative;
        padding-top: $js-product-wrapper-image-wrapper-height;
        img {
          display: block;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          max-height: 100%;
          max-width: 100%;
          margin: auto;
        }
      }
    }

    .item {
        float: left;
        width: $cart-description-width;
      padding-left: 15px !important;
      .description {
        margin-bottom: 10px;
        padding-right: 15px;
        .name {
        }
        .item-number {
          display: none;
          font-size: 0.8rem;
          padding: 3px 0 0;
        }
        .comments {
          font-style: italic;
        }
      }
    }
    .price {
        float: left;
        width: 50%;
        text-align: left;
        white-space: nowrap;
    }
    .total {
        float: left;
        width: 50%;
        text-align: right;
        white-space: nowrap;
        font-weight: bold;
    }    
    .qty {
        float: right;
        margin-top: 10px;
        width: $cart-description-width;
        padding-left: 15px;
        .label {
            display: none;
        }
        .value{
            font-size: 0.75em;
            width: 20%;
            text-align: center;
            display: inline-block;
        }

        .dynamic-cart-icon{
            width: 38%;
            text-align: center;
            display: inline-block;
            padding: 0;
            cursor: pointer;
            //float: left;
            border: 1px solid darken($cart-qty-changer-background, 5%);
            background: $cart-qty-changer-background;
            color: $cart-qty-changer-color;
            &:last-child{
                margin-right: 0;
            }
            &:before{
                font-family: $font-icon;
                font-size: 12px;
            }
            &:hover{
                border: 1px solid darken($cart-qty-changer-background, 10%);
                background: darken($cart-qty-changer-background, 5%);
            }
        }
        .minus{
            &:before{    
                content: $font-icon-minus;
            }
        }
        .plus{
            &:before{    
                content: $font-icon-plus;
            }
        }

    }
    .delete {
        position: absolute;
        top: 10px;
        right: $cart-side-padding;
        opacity: 0.3;
        &:after{
          font-family: $font-icon;
          content: $font-icon-delete;
          font-size: 18px;
          cursor: pointer;
          line-height: 1;
        }
        &:hover {
          opacity: 1;
        }
    }
  }

  #dc-totals {
    text-align: right;
    border-top: 1px solid darken($cart-item-border-color, 10%);
    padding: 15px $cart-side-padding 0;
    span {
      display: inline-block;
      font-size: 1.2em;
      font-weight: bold;
    }
    .value {
      transition: 0.3s;
      min-width: 100px;
    }
    .vat-total {
      span {
        font-size: 1em;
        color: #666;
        font-weight: normal;
      }
      .value {
        padding-right: 3px;
      }
    }
  }

  &.deleting #dc-totals .value {
    opacity: 0;
  }

    #dc-btns {
        padding: 10px $cart-side-padding 5px;
        overflow: hidden;
        .button {
            width: 48%;
            margin: 0;
            float: right;
            height: $cart-button-height;
            line-height: $cart-button-height;
            font-size: $cart-button-font-size;
            padding: 0;
            &#dc-checkout-btn{
                background: $cart-button-checkout-background;
                &:hover {
                    background: $cart-button-checkout-background-hover;
                }
            }
            &#dc-continue-btn{
                margin-right: 4%;
                background: $cart-button-continue-background;
                &:hover {
                    background: $cart-button-continue-background-hover;
                }
            }
        }
    }
}

html.dc-open{
  #side-menu-overlay{
      display: block;
  }  
}

html.dc-open.dynamic-cart-righty{
    body{
      overflow:hidden;
    }
    #dc-wrapper{
      right: 0;
      @media all and (min-width: $js-max-page-width) {
        right: calc(50% - #{$js-max-page-width/2});
      }                   
    }
    #side-menu-overlay{
        display: block;
    }
}


