#start-slider{
  .product-wrapper{
    position: relative;
  }
  .custom-item-text{
    >*, >*:hover{
      color: white;
    }
    position: absolute;
    bottom: 6%;
    right: 0;
    width: 50%;
    text-align:center;
  }
}