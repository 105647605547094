#related-category-placeholder{
  clear: both;
  margin-top: 30px;
  ul{
    font-size: 0;
  }
  &.vertical{
    ul{
      li{
        width: 100%;
        margin-bottom: 1%;
        .product-wrapper{
          position: relative;
        }
        .product-image{
          padding:15%;
          float: left;
          width: 15%;
          @media #{$medium-only}{
            padding: 7%
          }
          @media #{$large-up}{
            padding: 5%;
          }
        }
        .product-info {
          width: 70%;
          float: left;
          padding: 0px 15px;
          .product-list-article-number{
            border: none;
          }
          @media #{$medium-up}{
            width: 50%;
          }
        }
        .button-wrapper{
          font-size: 12px;
          width: 70%;
          padding-left: 15px;
          float: right;
          display: inline-block;
          @media #{$medium-up}{
            width: 35%;
            float: left;
            position: absolute;
            bottom: 10px;
            right: 15px;
          }
          .button-info{
            display: inline-block;
          }
        }
        
        .product-wrapper.no-buy-button{
          .product-buttons{
            clear: none;
          }
        }
      }
    }
  }
}

/// SCROLL TO RELATED PRODUCTS BUTTON \\\
.scroll-to-related {
  display: inline-block;
  border: 1px solid $border-color;
  text-align: center;
  width: 100%;
  padding: 10px;
  background: $js-buy-trail-color;
  color: white;
  &:hover{
    background: darken($js-buy-trail-color,5%);
  }
  &:before {
    content: 'Gå till Tillbehör & Förbrukningsmaterial';
    font-family: $font-family-sans-serif;
    font-size: 14px;
  }
}

.tab-item.tab-related {
  display: none;
}



//
//  Medium
//
@media #{$small-only} {
  @include calculate-item-width($js-total-products-small, $js-product-wrapper-margin-right, "#related-category-placeholder:not(.no-width) ul li");
}

//
//  Medium
//
@media #{$medium-only} {
  @include calculate-item-width($js-total-products-medium, $js-product-wrapper-margin-right, "#related-category-placeholder:not(.no-width) ul li");
}

//
//  Large
//
@media #{$large-only} {
  @include calculate-item-width($js-total-products-large, $js-product-wrapper-margin-right-large-up, "#related-category-placeholder:not(.no-width) ul li");
}

//
//  X-Large
//
@media #{$xlarge-only} {
  @include calculate-item-width($js-total-products-xlarge, $js-product-wrapper-margin-right-large-up, "#related-category-placeholder:not(.no-width) ul li");
}

//
//  XX-Large
//
@media #{$xxlarge-only} {
  @include calculate-item-width($js-total-products-xxlarge, $js-product-wrapper-margin-right-large-up, "#related-category-placeholder:not(.no-width) ul li");
}
