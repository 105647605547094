//
//    Footer
//
#page-footer {
  background: $footer-background;
  color: $footer-color;
  a, p, h1, h2, h3, h4, h5{
    color: $footer-color;
  }
  a:hover{
    color: darken($footer-color, 5%);
  }
  .culture-selector-wrapper{
    display: none;
  }
}
#footer-upper, #footer-lower {
  margin: 0 auto;
  max-width: $js-max-page-width;
  padding: 15px $page-padding 0 $page-padding;
}
#footer-upper{
  //border-bottom: 2px solid darken($footer-background, 5%);
}
#footer-lower {
  .grid-row{
    h3{
      background: lighten($footer-background, 10%);
      padding: 10px 0px 10px 20px;
    }
    >div{
      text-align: left;
      @media #{$medium-only}{
        //padding-left: 20px;
      }
      @media #{$small-only}{
        padding: 0 30px 0% 30px ;
        //margin-bottom: 6%;
        //border-bottom: 1px solid lighten($footer-background, 10%);
      }
      @media #{$large-up}{
        //padding-left: 30px;
      }
      img{
        margin-bottom: 15px;
      }
      li{
        padding-left: 20px;
      }
    }
    .col1of1.klarna-footer{
      text-align: center;

    }

  }
  .newsletter-box-header{
    span{
      font-size: 20px;
      padding: 10px 0px 9px 20px;
      background: lighten($footer-background, 10%);
      margin-top: 3px;
      width: 100%;
      @media #{$small-only}{
        font-size: $h4-font-size;
      }
    }
  }
  .newsletter-box-body{
    padding-left: 20px;
  }
}

@media #{$medium-only}{
  @include calculate-item-width(3, $grid-row-margin, "#footer-lower .grid-row .col1of5");
}

#jetshop-branding {
  background-color: $jetshop-branding-background;
  padding: 1px;
  clear: both;
}
.infoTextLogo {
  clear: both;
  width: 143px;
  margin: 5px auto 30px;
  @media #{$medium-down}{
    margin: 15px auto 30px;
  }
}

.social-icon{
  &:before{
    font-family: $font-icon;
    font-size: 14px;
    margin-right: 5px;
    width: 26px;
    height: 26px;
    display: inline-block;
    line-height: 26px;
    text-align: center;
    background: white;
    border-radius: 50%;
    color: #333;
    margin-bottom: 4px;
  }
  &.facebook:before{
    content: $font-icon-facebook;
  }
  &.instagram:before{
    content: $font-icon-instagram;
  }
  &.youtube:before{
    content: $font-icon-youtube;
  }
  &.linkedin:before{
    content: $font-icon-linkedin;
  }
}