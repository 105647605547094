//DESKTOP HEADER CAT NAV
#desktop-nav{
	display: none;
	@media #{$large-up}{	
		@if $desktop-nav{
		    display: block;
    		//border-top: 1px solid $desktop-nav-border;
			//border-bottom: 1px solid $desktop-nav-border;
			z-index:96;
			clear: both;
			width: 100%;
			position: absolute;
			@if $header-is-fixed{
				position: fixed;
			}
			top: $header-height-desktop;
			//border-bottom: 1px solid $header-border-color;
			@if $top-bar{
				top: $header-height-desktop + $top-bar-height;
			}	
			height: $desktop-nav-height + 1;
			background: $desktop-nav-background;
			.inner{
				clear: both;
				position: relative;
				width: 100%;
				max-width: $js-max-page-width;
				margin: 0 auto;
				padding: 0;
				@include clearfix();	
			}		    
	    }
	}
}

//DESKTOP CATEGORY NAVIGATION
.desktop-category-nav{
    display: none;
	@media #{$large-up}{
		display: block;
		//GENERAL
		ul{
			margin: 0;
			padding: 0;
			list-style-type: none;
			li{
				a{
					font-size: $dnav-l2-font-size;
				}
			}
		}

		//LEVEL 1
		>ul{
			padding-top: 10px; // padding-top: 0;
			>li{
				display: inline-block;
				margin-left: 2px;
				>a{
					display: inline-block;
					line-height: $desktop-nav-height - 9; //+1
					padding: 0 $dnav-l1-padding;
					color: $dnav-l1-color;
					background: $dnav-l1-background;
					font-size: $dnav-l1-font-size;
					font-weight: $dnav-l1-font-weight;
					border-bottom: none;
					letter-spacing: 1px;
					&:hover{
						//background: $dnav-l1-background-hover;
						//color: $dnav-l1-hover-color;
						//border-color: darken($dnav-l1-background-hover, 5%);
					}					
				}
				&:hover{
					>a{
						//background: $dnav-l1-background-hover;
						//color: $dnav-l1-hover-color;
					}
				}
				>ul{
					display: none;
				}
				>.sub-menu-wrapper{
					position: absolute;
					top: $desktop-nav-height;
					left: 0;
					background: $dnav-l2-background;
					border: 1px solid $dnav-l2-border;
					border-top: none;
					margin: 0;
					z-index: 100;
					display: none;
					.close-cat-menu{
						cursor: pointer;
						display: block;
						position: absolute;
						top: 11px;
						right: 17px;
						&:before{
							font-family: $font-icon;
							content: $font-icon-delete;
							font-size: 28px;			
						}
					}	
				}				
				&.cat-menu-open>a{
					//background: $dnav-l1-active-background !important;
					//color: $dnav-l1-active-color !important;
					z-index: 101;
				}
				&:first-child{
					margin-left: 0;
					a{
						//padding-left: 0;
					}
				}
				&.active{
					>a{
						//background: $dnav-l1-active-background !important;
						//color: $dnav-l1-active-color !important;
						//border-color: $border-color !important;
						&:hover{
							//background: $dnav-l1-active-hover-background !important;
							//color: $dnav-l1-active-color !important;
							//border-color: $border-color !important;
						}
					}
				}
				&.selected{
					>a{
						//background: $dnav-l1-selected-background !important;
						//color: $dnav-l1-selected-color !important;
						//border-color: $border-color !important;
						&:hover{
							//background: $dnav-l1-selected-hover-background !important;
							//color: $dnav-l1-selected-color !important;
							//border-color: $border-color !important;
						}
					}
				}
			}
		}

		//MEGA MENU	
		&.mega-menu{
			>ul{
				position: relative;
				//Level1
				> li{
					margin-right: 10px;
					&.has-subcategories{
						>.sub-menu-wrapper{
							width: 100%;
							padding: 20px $page-padding;
							border: 1px solid $dnav-sub-menu-border;
							>img{
								width: 40%;
								float: right;
								padding-right: 30px;
							}
							.menu-header{
								margin: 0 0 12px 0;
								width: 60%;
								float: left;
								a {
									font-size: $dnav-header-font-size;
									color: $dnav-l2-color;
								}
							}							
							.menu{
								width: 60%;
								float: left;
								clear: left;
								>ul{
									overflow: hidden;
									columns: 4;
									//Level2
									>li{
										//width: 33.33333%;
										//float: left;
										//padding: 3px 0px;
										//border-right: 1px solid $dnav-l2-border;

										>a, >a:active{
											font-size: $dnav-l2-font-size;
											color: $dnav-l2-color;
											font-weight: $dnav-l2-font-weight;
											background: $dnav-l2-link-background;
											display: inline-block;
											width: 95%;
											padding: 3px 0 3px 8px;
										}
										a:hover{
											color: $dnav-l2-hover-color;
										}
										&.show-all-link a{
											color: $dnav-show-all-link;
										}
										>ul{
											display: none;
											margin: 5px 0 0 10px;

											//Level3
											>li{
												>a, >a:active{
													font-size: $dnav-l3-font-size;
													font-weight:  $dnav-l3-font-weight;
													color:  $dnav-l3-color;
													&:hover{
														color: $dnav-l3-hover-color;
													}
												}
												>ul{
													display: none;
													//Level4
													>li{
														>a{
															&:hover{
															}
														}
													}
												}
												&.selected{
													>a{
														color: $dnav-l3-selected-color;
														text-decoration: $dnav-l3-selected-text-decoration;
														&:hover{
															color: $dnav-l3-selected-hover-color;
														}														
													}
												}							
											}
										}
										&.selected{
											>a{
												color: $dnav-l2-selected-color;
												text-decoration: underline;
												background: $dnav-l2-selected-link-background ;
												&:hover{
													color: $dnav-l2-selected-hover-color
												}												
											}
										}
									}
								}
							}
							.menu, .menu-header{
								width: 100%;
								float: left;
							}
							.list-category{
								width: 40%;
								float: right;
							}
						}
						&.cat-menu-open{
							>.sub-menu-wrapper{
								display: block;
							}							
						}
						&:hover{
							>.sub-menu-wrapper{
								//display: block;
							}
						}
					}
					&.selected, &.active{
						>a{

						}
					}
					li.selected{
						>a{
							//@include font-primary();
						}
					}
					&:first-child{
						margin-left: 0;
					}
				}			
			}
		}
		
		//VERTICAL MENU
		&.vertical-menu{
			>ul{
				//Level1
				> li{
					position: relative;
					&.has-subcategories{
						>ul{
							width: auto;
							//Level2
							>li{
								width: auto;
								padding: 5px $desktop-nav-height/2.6667;
								a, a:active{
									color: $dnav-l2-color;
								}
								a:hover{
									color: $dnav-l2-hover-color;
								}
								>ul{
									margin: 5px 0 0 0;

									//Level3
									>li{
										>a, >a:active{
											font-size: 90%;
											&:hover{
												color: darken(white, 15%);
												background: none;
											}
										}
										>ul{
											>li{
												>a{
													font-size: 80%;
													&:hover{
														background: none !important;
														color: darken(white, 15%);
													}
												}
											}
										}
										&.selected{
											>a{
												border: none !important;
												background: none !important;
											}
										}							
									}
								}
								&.selected{
									>a{
										//border: none !important;
									}
								}
							}
						}
						&:hover{
							>ul{
								display: block;
							}
						}
					}
					&.selected, &.active{
						>a{

						}
					}
					li.selected{
						>a{
							//@include font-primary();
						}
					}
					&:first-child{
						margin-left: 0;
					}
				}			
			}
		}

	}
}


html.cat-menu-open{
	#header, #top-bar, #desktop-nav{
		z-index: 100;
	}
	#side-menu-overlay{
		display: block;
	}
}


/// Diffrent colors in desktop nav

//.desktop-category-nav > ul > li:nth-of-type(1) > a {
//	background: $festool-blue;
//	background: -webkit-linear-gradient(left, $festool-blue, lighten($festool-blue,10%)); /* For Safari 5.1 to 6.0 */
//	background: -o-linear-gradient(right, $festool-blue, lighten($festool-blue,10%)); /* For Opera 11.1 to 12.0 */
//	background: -moz-linear-gradient(right, $festool-blue, lighten($festool-blue,10%)); /* For Firefox 3.6 to 15 */
//	background: linear-gradient(to right, $festool-blue, lighten($festool-blue,10%)); /* Standard syntax */
//	color: $festool-green;
//}
//.desktop-category-nav > ul > li:nth-of-type(2) > a {
//	background: black;
//	background: -webkit-linear-gradient(left, black, lighten(black,12%)); /* For Safari 5.1 to 6.0 */
//	background: -o-linear-gradient(right, black, lighten(black,12%)); /* For Opera 11.1 to 12.0 */
//	background: -moz-linear-gradient(right, black, lighten(black,12%)); /* For Firefox 3.6 to 15 */
//	background: linear-gradient(to right, black, lighten(black,12%)); /* Standard syntax */
//	color: $schneider-yellow;
//
//}