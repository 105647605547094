.styled-select{
  position: relative;
  overflow: hidden;
  select {
    -webkit-appearance: none;
    appearance: none;
    background: #FFF;
    border: 1px solid #DDD;
    padding: 0 55px 0 10px;
    color: $js-body-font-color;
    height: 50px !important;
    width: 100%;
    -webkit-user-select: none;
    outline: none;
    cursor: pointer;
    &::-ms-expand {
      display: none;
    }
  }
  &:after {
    content: $font-icon-down;
    font-family: $font-icon;
    height: 43px;
    line-height: 50px;
    width: 50px;
    border-left: 1px solid $border-color;
    display: block;
    position: absolute;
    right: 0;
    top: 5px;
    background: transparent;
    text-align: center;
    pointer-events: none;
  }
}
body.browser-firefox {
  .styled-select  {
    select {
      -moz-appearance: none;
      -moz-user-select: none;
      color: white;
      color: rgba(255,255,255,0);
      text-shadow: 0 0 0 white;
    }
  }
}
body.browser-internet-explorer {
  .styled-select  {
    &:after {
      display: inline-block;
    }
  }
}