//MAIN HEADER
#header {
  z-index: 96;
  clear: both;
  width: 100%;
  height: $header-height + 1;
  background: $header-background;
  //border-bottom: 1px solid $header-border-color;
  color: $header-color;
  @media #{$large-up}{
    position: absolute;
    height: $header-height-desktop + 1;
    @if $top-bar {
      top: $top-bar-height;
    } @else {
      top: 0;
    }
    @if $header-is-fixed {
      position: fixed;
    }
  }
  @media #{$medium-down}{
    position: fixed;
  }
  top: 0;
  a, a:hover {
    color: $header-icon-color;
  }
  .inner {
    margin: 0 auto;
    max-width: $js-max-page-width;
    position: relative;
    .logo-wrapper {
      text-align: center;
      height: $header-height;
      margin: 0;
      float: none;
      .logo-body {
        display: inline-block;
        max-width: 99999px;
        height: 100%;
      }
      input {
        display: inline-block;
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        margin: 0 auto;
        padding: 5px 0;
        height: $header-logo-maxheight;
        margin-top: ($header-height - $header-logo-maxheight) / 2;
        max-width: $header-logo-maxwidth;
      }
      @media #{$large-up}{
        padding-left: 10px;
        height: $header-height-desktop;
        float: left;
        input {
          height: $header-logo-maxheight-desktop;
          margin-top: ($header-height-desktop - $header-logo-maxheight-desktop) / 2;
          max-width: $header-logo-maxwidth-desktop;
          position: relative;
        }
      }
    }
    .InfoTextWrapper {
      padding-top: 16px;
      float: left;
      margin-left: 40px;
      @media #{$medium-down}{
        display: none;
      }
      ul {
        float: left;
        li {
          margin-bottom: 5px;
          &:last-child {
            margin-bottom: 0px;
          }
          &:before {
            margin-right: 5px;
            width: 20px;
            height: 20px;
            display: inline-block;
            border-radius: 50%;
            color: white;
            text-align: center;
            background: $js-buy-trail-color;
            font-family: $font-icon;
            content: $font-icon-check;
          }
        }
      }
      > p {
        float: left;
        padding-top: 10px;
        margin-left: 30px;
        img {
          width: 50px;
        }
      }
    }
    .menu-buttons {
      position: absolute;
      top: 0;
      height: $header-height;
      line-height: $header-height;
      @media #{$large-up}{
        height: $header-height-desktop;
        line-height: $header-height-desktop;
      }
    }
    .icon {
      font-size: 20px;
      display: inline-block;
      &:before {
        display: inline-block;
      }
      height: $header-height;
      line-height: $header-height;
    }
    .left-side {
      left: $header-height/4.5;
      > * {
        float: left;
        margin-right: 15px;
        @media #{$large-up}{
          margin-right: 25px;
        }
      }
      @media #{$large-up}{
        top: 0;
        left: $header-height-desktop/4.5;
        display: none;
      }
    }
    .right-side {
      right: $header-height/4.5;
      @media #{$large-up}{
        right: $page-padding;
      }
      > * {
        float: right;
        margin-left: 15px;
        @media #{$large-up}{
          margin-left: 25px;
        }
      }
      &.cart-detailed {
        @media #{$large-up}{
          padding-top: $cart-detailed-top-position;
          #cart-button {
            padding: 0 15px;
            margin-left: 0;
            background: $primary-color; //$js-buy-trail-color;
            height: $cart-detailed-button-height;
            line-height: $cart-detailed-button-height;
            color: white;
            .icon {
              height: $cart-detailed-button-height;
              line-height: $cart-detailed-button-height;
              &:before {
                font-size: 24px;
              }
            }
            .items {
              top: 3px;
              left: 24px;
              right: initial;
              background: #333;
              color: white;
              height: 15px;
              width: 15px;
              line-height: 15px;
              font-size: 10px;
            }
            .sum {
              vertical-align: top;
              margin-left: 11px;
            }

            .leasing {
              display: none;
              vertical-align: top;
              padding: 0 5px;

              &::before {
                content: '- ';
                display: inline-block;
                padding-right: 6px;
              }
            }

            &:after {
              font-family: $font-icon;
              content: $font-icon-down;
              line-height: $cart-detailed-button-height;
              vertical-align: top;
              display: inline-block;
              margin-left: 7px;
              font-size: 12px;
            }
          }
          #checkout-button {
            padding: 0 20px;
            margin-left: $cart-detailed-margin;
            background: $js-buy-trail-color;
            height: $cart-detailed-button-height;
            line-height: $cart-detailed-button-height;
            color: white;
            &:after {
              font-family: $font-icon;
              content: $font-icon-right;
              line-height: $cart-detailed-button-height;
              vertical-align: top;
              display: inline-block;
              margin-left: 7px;
              font-size: 12px;
            }
          }
          #customer-care-wrapper {
            margin-left: 0;
            margin-right: $cart-detailed-margin;
            background: $primary-color; //$js-buy-trail-color;
            height: $cart-detailed-button-height;
            line-height: $cart-detailed-button-height;
            #customer-care-button {
              padding: 10px 20px;
              &:after {
                color: white;
                font-family: $font-icon;
                content: $font-icon-down;
                line-height: $cart-detailed-button-height;
                vertical-align: top;
                display: inline-block;
                margin-left: 7px;
                font-size: 12px;
              }
            }
            .icon {
              height: $cart-detailed-button-height;
              line-height: $cart-detailed-button-height;
              &:before {
                color: white;
                font-size: 22px;
              }
            }

            .customer-care-box {
              top: $header-height-desktop - $cart-detailed-top-position;
            }
          }
          #FreeFreight_Cart {
            position: absolute;
            margin: 0;
            bottom: $cart-detailed-freight-position;
            left: 0;
            width: 100%;
            background: #EEE;
            color: $js-body-font-color;
            font-size: 12px;
            line-height: $cart-detailed-freight-height;
            height: $cart-detailed-freight-height;
            display: none;
            padding: 0;
          }
        }
        @media #{$medium-down}{
          .sum {
            display: none !important;
          }
          #checkout-button {
            display: none !important;
          }
        }
      }
      &.cart-icon {
        .sum {
          display: none !important;
        }
        .icon {
          @media #{$large-up}{
            line-height: $header-height-desktop;
            height: $header-height-desktop;
            text-align: center;
          }
        }
      }
      @media #{$large-up}{
        top: 0;
        right: $header-height-desktop/3;
      }
    }
    #menu-button {
      .icon:before {
        font-family: $font-icon;
        content: $font-icon-menu;
      }
      .text {
        display: none;
        @if $header-menu-title {
          @media #{$medium-only}{
            display: inline-block;
            text-transform: uppercase;
            position: relative;
            top: -4px;
            //font-weight: bold;
            font-size: 18px;
            margin-left: 5px;
          }
        }
      }
    }
    #search-button {
      .icon {
        font-size: 16px;
        &:before {
          content: $font-icon-search;
        }
      }
      @media #{$large-up}{
        display: none;
      }
    }
    #checkout-button {

    }
    #cart-button {
      position: relative;
      > span {
        display: inline-block;
      }
      .items {
        display: none;
        color: $cart-item-color;
        background: $cart-item-background;
        border-radius: 50%;
        height: 16px;
        width: 16px;
        font-size: 10px;
        text-align: center;
        line-height: 17px;
        position: absolute;
        top: 12px;
        right: -6px;
        @media #{$large-up}{
          font-size: 12px;
          top: 24px;
          right: -4px;
          height: 22px;
          width: 22px;
          line-height: 23px;
        }
      }
      .icon {
        &:before {
          content: $font-icon-cart;
          font-size: 20px;
          //width: 32px;
          //height: 32px;
          //background-image: url(#{$site-url}/stage/images/responsive-base/cart.png);
          //background-size: contain;
          @media #{$large-up}{
            //width: 36px;
            //height: 36px;
            font-size: 36px;
          }
        }
      }
      .text {
        line-height: $header-height;
        display: none;
      }
      .text-items {
        line-height: $header-height;
        display: none;
        &:before {
          content: "(";
          margin-right: 2px;
        }
        &:after {
          content: ")";
          margin-left: 2px;
        }
      }
      @media #{$medium-down}{
        .text, .text-items {
          display: none;
        }
      }
    }
    #customer-care-wrapper {
      position: relative;
      .icon {
        &:before {
          content: $font-icon-user;
          display: inline-block;
          font-size: 20px;
          @media #{$large-up}{
            font-size: 36px;
          }
        }
      }
      .customer-care-box {
        display: none;
        position: absolute;
        right: -30px;
        width: 300px;
        padding: 20px;
        background: $header-background;
        border: 1px solid $header-border-color;
        top: $header-height;
        .cart-arrow {
          right: 42px;
          @media #{$medium-down}{
            right: 26px;
          }
        }
        @media #{$large-up}{
          top: $header-height-desktop;
        }
      }
    }
  }
  #FreeFreight_Cart {
    display: none;
  }

  @media #{$medium-down}{

    #cart-button .leasing {
      display: none !important;
    }
  }

}

//CUSTOMER CARE BOX
.customer-care-box .content {
  text-align: center;
  color: white;
  h4 {
    color: white;
    margin: 25px 0 10px 0;
    &:first-child {
      margin-top: 0;
    }
  }
  ul {
    li {
      display: inline-block;
      padding: 0 3px;
    }
  }
  .phone {
    font-size: 26px;
    font-weight: 300;
  }
  .bottom-buttons {
    padding: 10px 0;
    border-top: 1px solid $border-color;
    a {
      display: inline-block;
      width: 45%;
      float: left;
      border: 2px solid $primary-color;
      line-height: 40px;
      &:first-child {
        margin-right: 10%;
      }
      &.login-text {
        background: $primary-color;
        color: white !important;
      }
    }
  }
}

//SEARCH IN HEADER
#header .custom-search-wrapper {
  display: block;
  position: absolute;
  top: $search-header-top;
  left: $search-header-left;
  width: $search-header-width;
  height: $search-header-height;
  //border: 1px solid $search-header-border-color;
  overflow: hidden;
  .search-box-body {
    overflow: visible;
    .search-box-input {
      float: none;
    }
  }
  input {
    height: $search-header-height - 2;
    font-size: $search-header-font-size-desktop;
    color: $search-header-color-desktop;
    background: $search-header-background-desktop;
    border: none;
    width: 80%;
    display: block;
    float: left;
    padding: 0 10px;
  }
  .button {
    margin: 0;
    padding: 0;
    text-align: center;
    height: $search-header-height - 2;
    line-height: $search-header-height - 2;
    background: $search-header-button-background;
    display: block;
    float: right;
    width: 20%;
    font-weight: normal;
    &:before {
      font-family: $font-icon;
      content: $font-icon-search;
      font-size: $search-header-icon-size-desktop;
      color: $search-header-icon-color-desktop;
    }
    &.close {
      display: none;
    }
  }
  @media #{$medium-down}{
    display: none;
    border: none;
    background: $search-header-background;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    input {
      width: 70%;
      height: $header-height;
      background: $search-header-background;
      color: $search-header-color;
      //border-bottom: 1px solid $search-header-button-background;
    }
    .button {
      width: auto !important;
      height: $header-height;
      line-height: $header-height;
      position: absolute;
      right: 48px;
      background: $search-header-background;
      &:before {
        font-size: $search-header-icon-size;
        color: $search-header-icon-color;
      }
      &.close {
        display: block;
        right: $header-height/4.5;
        &:before {
          color: $search-header-icon-color;
          content: $font-icon-delete;
        }
      }
    }
  }
  @media #{$large-up}{
    display: block !important;
  }
}

.vat-switcher-button-wrapper {
  float: right;
  margin-right: 0 !important;
  a {
    padding: 0 15px !important;
    background: $vat-switcher-background !important;
    color: $vat-switcher-color !important;
    &.selected {
      background: $vat-switcher-selected-background !important;
      color: $vat-switcher-selected-color !important;
      text-decoration: underline;
    }
  }
  @media #{$medium-down} {
    display: none;
  }
}

/*#vat-switcher{
	float: right;
	width: 100%;
	height: 40px;
	text-align: center;
	a{
		font-size: 14px;
		display: block;
		float: left;
		width: 50%;
		height: 100%;
		line-height: 40px;
		background: $vat-switcher-background;
		color: $vat-switcher-color;
		&.selected{
			background: $vat-switcher-selected-background;
			color: $vat-switcher-selected-color;
		}
	}
}*/

#top-bar #vat-switcher {
  width: 200px;
  height: $top-bar-height;
  a {
    line-height: $top-bar-height;
  }
}

//HIDE ORG SEARCH
.search-box-wrapper, .small-cart-wrapper {
  display: none;
}

.custom-search-wrapper input:focus {
  outline: none;
}

html.search-open {
  #header {
    z-index: 999;
  }
  #side-menu-overlay {
    display: block;
  }
}

//
//  SCROLLING
//
#header, #desktop-nav, #dc-wrapper.dropdown {
  -webkit-transition: top 0.3s; /* Safari */
  transition: top 0.3s;
}

.custom-search-wrapper {
  transition: left 0.3s;
}

html.scrolling {
  @media #{$large-up}{
    @if $header-is-fixed {
      #header {
        height: $header-height-desktop-scrolled;
        top: 0;
        .logo-wrapper {
          height: $header-height-desktop-scrolled;
          input {
            height: $header-logo-maxheight-desktop-scrolled;
            margin-top: ($header-height-desktop-scrolled - $header-logo-maxheight-desktop-scrolled) / 2;
            max-width: $header-logo-maxwidth-desktop;
          }
        }
        .custom-search-wrapper {
          top: $search-header-top-scrolled;
          height: $search-header-height-scrolled;
          left: $search-header-left-scrolled;
          input {
            height: $search-header-height-scrolled - 2;
          }
          .button {
            height: $search-header-height-scrolled - 2;
            line-height: $search-header-height-scrolled - 2;
          }
        }
        .right-side {
          height: $header-height-desktop-scrolled;
          line-height: $header-height-desktop-scrolled;
          &.cart-icon {
            .header-button {
              margin-left: 10px;
              .icon {
                width: $header-height-desktop-scrolled - 20;
                line-height: $header-height-desktop-scrolled - 20;
                height: $header-height-desktop-scrolled - 20;
                margin-top: 10px;
                &:before {
                  font-size: 26px !important;
                }
              }
              .items {
                top: 11px !important;
                left: 19px !important;
                height: 18px !important;
                width: 18px !important;
                line-height: 19px !important;
              }
            }
          }
          &.cart-detailed {
            padding-top: 14px;
            #checkout-button {
              margin-left: 3px;
            }
            #FreeFreight_Cart {
              font-size: 11px;
              line-height: 20px;
              height: 20px;
              bottom: 4px;
            }
          }
        }
      }
      #dc-wrapper.dropdown, .customer-care-box {
        top: $header-height-desktop-scrolled !important;
      }
      #desktop-nav {
        top: $header-height-desktop-scrolled !important;
        display: none;
      }
      &.up {
        #desktop-nav {
          display: block;
        }
      }
    }
  }
}

//
//  FIX FOR STICKY HEADER
//

#content-wrapper {
  padding-top: $header-height;
  @media #{$large-up}{
    padding-top: $header-height-desktop;
    @if $desktop-nav
    & & $top-bar {
    padding-top: $header-height-desktop + $top-bar-height + $desktop-nav-height;
  } @else if $desktop-nav {
    padding-top: $header-height-desktop + $desktop-nav-height;
  } @else if $top-bar {
    padding-top: $header-height-desktop + $top-bar-height;
  }
  }
}

