$filter-show-amount:							false;
$filter-mobile-buttons-height:					40px;


/* ---------------------------------
GENERAL
 ----------------------------------*/
.filter-control-body {
	display: none;
}
.filter-no-results{
	clear: both;
	display: block;
}
.styled-area{
	#filter-custom-wrapper, #filter-custom-mobile-buttons{
		display: none !important;
	}
}

html.page-listproducts.ebeling-filter {

}

.input-box {
	width: 16px;
	height: 16px;
	border: 1px solid $border-color;
	background: white;
	margin-right: 5px;
	text-align: center;
	line-height: 16px;
	vertical-align: text-bottom;
	position: relative;
	top: -1px;
	border-radius: 50%;
	display: inline-block;
}
.item.selected{
	.input-box {
		background: $js-buy-trail-color;
		border-color: darken($js-buy-trail-color, 5%);
	}
	/*.input-box:after{
        font-size: 12px;
        font-family: $font-icon;
        content: $font-icon-check;
        color: $js-buy-trail-color;
        position: relative;
        top: -2px;
    }*/
}

.filter.price{
	.noUi-base{

	}
	.values{
		font-size: 11px;
		text-align: center;
		margin-bottom: 5px;
		.separator{
			margin: 0 5px;
		}
	}
}


/* ---------------------------------
LEFT
 ----------------------------------*/
html.page-listproducts.filter-type-left {
	#filter-custom-wrapper{
		@media #{$large-up}{
			padding-top: 1%;
		}
		>h3{
			text-align: center;
			padding: 20px 0;
			border-bottom: 1px solid $border-color;
			font-size: 1.2rem;
			display: none;
			@media #{$medium-down}{
				display: block;
				padding: 15px 0 20px 0;
				margin: 0;
				margin-bottom: 10px;
			}
		}
		>.clear-filter{
			display: block;
			cursor: pointer;
			color: #333;
			padding: 4%;
			margin-bottom: 15px;
			border-bottom: 1px solid $border-color;
			text-decoration: underline;
			text-align: center;
			@media #{$large-up}{
				padding: 4% 15% 4% 15%;
			}
		}
		>.close-filter{
			display: none;
			@media #{$medium-down}{
				cursor: pointer;
				display: block;
				position: absolute;
				top: 7px;
				left: 13px;
				&:before{
					font-family: $font-icon;
					content: $font-icon-delete;
					font-size: 28px;
				}
			}
		}
		.filter{
			padding: 4% 15% 4% 15%;
			clear: both;
			@media #{$medium-down}{
				margin: 0 4%;
				padding: 10px 0;
				//border-bottom: 1px solid $border-color;
				&.active .items{
					display: block;
				}
			}
			>h4{
				cursor: pointer;
				text-transform: capitalize;
				&:after{
					font-family: $font-icon;
					content: $font-icon-down;
					float: right;
					font-size: 16px;
				}
				@media #{$medium-down}{
					padding: 5px 0;
					margin: 0;
				}

			}
			/*&.close{
                .items{
                    display: none;
                }
                >h3:after{
                    content: "\f106";
                }
            }*/
			.items{
				@media #{$medium-down}{
					display: none;
				}
				.item{
					display: block;
					padding: 5px 0;
					border-bottom: 1px solid $border-color;
					>span{
						display: inline-block;
					}
					@media #{$medium-down}{
						padding: 8px 0;
					}
				}
			}
			&.boolean{
				.items{
					.item{
						border-bottom: none;
						display: inline;
						margin-right: 5px;
						&:last-child{
							margin-right: 0;
						}
						.input-box{
							border-radius: 0;
						}
						&.selected{
							.input-box{
								background: transparent;
								border: 1px solid $border-color;
							}
							.input-box:after{
								font-size: 12px;
								font-family: $font-icon;
								content: $font-icon-check;
								color: $js-buy-trail-color;
								position: relative;
								top: -2px;
							}
						}
					}
				}
			}
		}
	}

	@media #{$large-up}{
		#main-area, #sub-nav-wrapper{
			width: 80%;
			float: right;
			padding-left: 20px;
			padding-right: 20px;
		}
		#filter-custom-mobile-buttons{
			display: none;
		}
		#filter-custom-wrapper{
			width: 20%;
			float: left;
		}
		#main-area{
			border-left: 1px solid $border-color;
		}
	}

	@media #{$medium-down}{
		#filter-custom-mobile-buttons{
			border-top: 1px solid $border-color;
			border-bottom: 1px solid $border-color;
			height: $filter-mobile-buttons-height + 2;
			margin-bottom: 10px;
			.buttons{
				overflow: hidden;
				line-height: $filter-mobile-buttons-height;
				background: white;
				>a{
					float: left;
					display: inline-block;
					width: 50%;
					text-align:center;
					&:after{
						font-family: $font-icon;
						content: $font-icon-sorting;
						font-size: 14px;
						margin-left: 5px;
					}
					&:first-child{
						border-right: 1px solid $border-color;
						&:after{
							font-family: $font-icon;
							content: $font-icon-filter;
							//font-size: 14px;
						}
					}
				}
			}
		}
		#filter-custom-wrapper{
			position:fixed;
			background: white;
			overflow: auto;
			top: $header-height + $filter-mobile-buttons-height;
			width: 300px;
			height: 100%;
			z-index: 9998;
			left: -300px;
			padding-bottom: 50px + $header-height + $filter-mobile-buttons-height;
			-webkit-transition: left .3s;
			-moz-transition: left .3s;
			-ms-transition: left .3s;
			-o-transition: left .3s;
			transition: left .3s;
			border-right: 1px solid $border-color;
			border-top: 1px solid $border-color;
			>.finnished-filter{
				display: block !important;
				background: white;
				position:fixed;
				background: white;
				overflow: auto;
				bottom: 0;
				width: 300px;
				z-index: 9999;
				left: -300px;
				-webkit-transition: left .3s;
				-moz-transition: left .3s;
				-ms-transition: left .3s;
				-o-transition: left .3s;
				transition: left .3s;
				border-top: 1px solid $border-color;
				border-right: 1px solid $border-color;
				span{
					cursor: pointer;
					width: 92%;
					margin: 2% 4%;
					height: 40px;
					line-height: 40px;
					background: #333;
					color: white;
					text-align: center;
					display: block;
				}
			}
		}
	}

	#filter-chosen{
		margin: 5% 10% 5% 10%;
		border: 1px solid $border-color;
		padding: 10px;
		.titel{
			margin-bottom: 20px;
			border-bottom: 1px solid #333;
			padding: 10px 0;
		}
		.clear{
			margin-top: 20px;
			display: block;
			cursor: pointer;
		}
		.item{
			margin: 5px 0;
			.name{

			}
			.value{
				font-weight: $font-weight-bold;
			}
			.delete{
				float: right;
				cursor: pointer;
				&:after{
					font-family: $font-icon;
					content: $font-icon-delete;
				}
			}
		}
	}
}

html.filter-open{
	#filter-custom-wrapper{
		left: 0 !important;
		>.finnished-filter{
			left: 0 !important;
		}
	}
	#side-menu-overlay{
		display: block;
		top: $header-height + $filter-mobile-buttons-height;
	}
}

/* ---------------------------------
STANDARD
 ----------------------------------*/
html.filter-type-standard{
	#filter-custom-wrapper{
		overflow: hidden;
		padding: 15px 0 0 0;
		border-top: 1px solid $border-color;
		text-align:left;
		@media #{$large-up}{
			text-align: left;
		}
		>h3{
			@media #{$large-up}{
				float: left;
				margin-top: 14px;
				margin-right: 20px;
				font-size: 13px;
				color: #BBB;
				text-transform: none;
				font-weight: normal;
			}
		}
		.clear-filter{
			padding: 10px 0;
			color: $js-buy-trail-color;
			display: block;
			cursor: pointer;
			@media #{$large-up}{
				float: left;
				padding: 0;
				margin-top: 14px;
				margin-right: 20px;
				font-size: 13px;
			}
		}
		.filter{
			max-width: 310px;
			margin-bottom: 10px;
			text-align: left;

			&.input{

			}
			.input-box {
				width: 16px;
				height: 16px;
				border: 1px solid #CCC;
				background: white;
				margin-right: 5px;
				text-align: center;
				line-height: 16px;
				vertical-align: text-bottom;
				position: relative;
				top: -1px;
				border-radius: 5px;
				display: inline-block;
			}
			.item.selected{
				.input-box:after{
                    font-size: 18px;
                    font-family: $font-icon;
                    content: $font-icon-check;
                    color: $js-buy-trail-color;
                    position: relative;
                    top: -2px;
                }
			}
			&.price{
				padding: 5px 15px 0 5px;
				h5{
					display: none;
					margin: 10px 0 0 0;
				}
				@media #{$large-up}{
					padding: 0;
				}
			}
			@media #{$large-up}{
				text-align:left;
				width: auto;
				margin-right: 20px;
				float: left;
				&.input{
					margin-top: 15px;
				}
				&.price{
					width: 200px;
				}
			}
		}
	}
}



