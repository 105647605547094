//BUTTONS
#attribute-select[data-type="button"]{
  background: $attribute-wrapper-background;
  >div{
    clear: both;
    margin: 10px 0;
    overflow: hidden;
    .title{
      .text{
        font-weight: bold;
      }
      .choose-text{
        font-style: italic;
        &:before{
          content: " (";
        }
        &:after{
          content: ")";
        }
        &.select-error{
          color: red;
        }
      }
    }
    .item-wrapper{
      margin: 7px 0;
      .item{
        width: 9%;
        float: left;
        margin-right: 2%;
        text-align: center;
        padding: 10px 0;
        border: $attribute-button-border;
        background: $attribute-button-background;
        color: $attribute-button-color;
        &.selected{
          border: $attribute-button-selected-border;
          background: $attribute-button-selected-background;
          color: $attribute-button-selected-color;
        }
        &[data-is-buyable="false"]{
          border: $attribute-button-disabled-border;
          background: $attribute-button-disabled-background;
          color: $attribute-button-disabled-color;
          text-decoration: line-through;
          &.selected{
            border: $attribute-button-disabled-selected-border;
            background: $attribute-button-disabled-selected-background;
            color: $attribute-button-disabled-selected-color;
          }
        }
        .value{
          font-size: 11px;
        }
        >span{
          display: block;
        }
        .check-box{
          display: none;
        }
        .image-box{
          img{
            width: 100%;
            display: block;
          }
        }
        .stock{
          display: none;
        }
        .price{
          color: #333;
          margin-left: 5px;
          .previous{
            display: none;
            margin-left: 5px;
            text-decoration: line-through;
          }
        }
      }
      .attributes-2-inner{
        display: none;
        .item{

        }
      }
    }
  }
  .attributes-2{
    margin-top: 15px;
    .attributes-2-inner{
      display: block;
      .item{
        border: $attribute-button-attr2-border;
        background: $attribute-button-attr2-background;
        color: $attribute-button-attr2-color;
      }
    }
  }
}