html{
	background: $canvas-background-color;
}

body{
	width: 100%;
}

#wrapper-page-content{
	position: relative;
}

//LARGE PAGE PADDING
@media #{$large-up}{
	#top-bar .inner, #sub-nav-wrapper, #main-area, #category-navigation, .footer-lower, .footer-upper{
		padding-left: $page-padding !important;
		padding-right: $page-padding !important;
	}
	.logo-wrapper{
		padding-left: $page-padding !important;
	}
	#user-area{
		right: $page-padding !important;
	}	
}

//CONTENT LINK
div.category-header-subtitle, .PageDetailsWrapper{
	a{
		color: $content-link-color;
		font-weight: $content-link-weight;
		&:hover{
			color: darken($content-link-color, 10%);
		}
	}

}

.icon{
	&:before{
		font-family: $font-icon;
	}
}

.cart-arrow, .cart-arrow:after{
	display: block;
	position: absolute;
	width: 0;
	height: 0;
	border-style: solid;    
}
.cart-arrow{
	border-color: transparent transparent $border-color;
	border-width: 0 11px 11px;
	right: 0;
	margin-left: -11px;
	top: -11px;		        
	&:after{
	  border-color: transparent transparent #fff;
	  border-width: 0 10px 10px;
	  content: " ";
	  top: 1px;
	  margin-left: -10px;
	}
}

#side-menu-overlay{
	background: rgba(0,0,0,0.3);
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0; //$header-height;
    left: 0;
    z-index: 98;
	@media all and (min-width: $js-max-page-width) {
		//width: $js-max-page-width;
		//left: calc(50% - #{$js-max-page-width/2};
	}    
}

//FOLDING BEHAVIUOR
#document-wrapper{
    -webkit-transition: margin .3s;
    -moz-transition: margin .3s;
    -ms-transition: margin .3s;
    -o-transition: margin .3s;
    transition: margin .3s;   
    z-index: 98;
    margin: 0;
    width: 100%;    
}


#side-menu{
	z-index: 99;
}

html.menu-open{
	body{
		overflow: hidden;
	}
	#side-menu{
		left: 0;
		@media all and (min-width: $js-max-page-width) {		
			//left: calc(50% - #{$js-max-page-width/2};
		}	 
	}
	#document-wrapper{

	}
	#side-menu-overlay{
		display: block;
	}
}

.canvas-mask{
	display: none;
	/*@media all and (min-width: $js-max-page-width) {
		display: block;
		position:fixed;
		top: 0;
		background: $canvas-background-color;
		z-index: 99999;
		height: 100%;
		width: $canvas-width;
		&.left-off{
			left: calc(50% - #{$js-max-page-width/2 + $canvas-width};
			//border-right: 1px solid darken($canvas-background-color, 5%);
    		//box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.05);		
		}
		&.right-off{
			right: calc(50% - #{$js-max-page-width/2 + $canvas-width};
			//border-left: 1px solid darken($canvas-background-color, 5%);
			//box-shadow: -2px 0px 2px rgba(0, 0, 0, 0.05);
		}
	}*/
}
#ajax-spinner{
	text-align: center;
	padding: 5px 0;
}

