//DROPDOWN
$js-dc-dropdown-width-large-up:     480px;
$js-dc-dropdown-width-medium:       400px;
$js-dc-dropdown-wrapper-padding:    20px;



//RIGHTY
$cart-background: 				    white;
$cart-color: 					    #333;
$cart-border: 					    #EEE;
$cart-width-small: 				    280px;
$cart-width-medium: 			    320px;
$cart-width-large: 				    380px;
$cart-side-padding: 			    10px;

$cart-header-font-size: 		    12px;
$cart-header-font-weight: 		    600;

$cart-row-border: 				    #DDD;
$cart-row-font-size: 			    14px;
$cart-row-font-weight: 			    normal;

$cart-item-border-color: 		    #eee;

$cart-img-width:				    25%;
$cart-description-width: 		    75%;

$cart-sum-font-size: 			    14px;
$cart-sum-font-weight:			    600;
$cart-total-font-size: 			    18px;
$cart-total-font-weight:		    600;


$cart-button-height:                40px;
$cart-button-font-size:	            14px;

$cart-button-checkout-background:       $js-buy-trail-color;
$cart-button-checkout-background-hover: lighten($js-buy-trail-color, 10%);
$cart-button-continue-background:       #777;
$cart-button-continue-background-hover: lighten(#777, 10%);

$cart-name-color:                   #000;
$cart-price-color:                  #666;
$cart-qty-color:                    #666;

$cart-qty-changer-color:            #777;
$cart-qty-changer-background:       #F9F9F9;
