//RADIO2
#attribute-select[data-type="radio2"]{
  padding: 0;
  >div{
    .title{
      padding: 15px 0;
      .text{
        font-weight: bold;
      }
      .choose-text{
        font-style: italic;
        &:before{
          content: " (";
        }
        &:after{
          content: ")";
        }
        &.select-error{
          color: red;
        }
      }
    }
    .item-wrapper{
      border-bottom: 1px solid #DDD;
      &:first-child{
        border-top: 1px solid #DDD;
      }
      &:nth-of-type(2n+1){
        background: #F5F5F5;
      }
      .item{
        padding: 15px;
        display: block;
        >span{
          display: inline-block;
        }
        .check-box{
          width: 16px;
          height: 16px;
          border: 1px solid #CCC;
          background: white;
          margin-right: 5px;
          text-align: center;
          line-height: 16px;
          vertical-align: text-bottom;
          position: relative;
          top: -1px;
          border-radius: 50%;
        }
        .value{
          color: #333;
        }
        .stock{
          float: right;
          font-size: 10px;
          .stock-label{
            margin-right: 3px;
          }
          .stock-value{

          }
        }
        .price{
          color: #333;
          margin-left: 5px;
          .previous{
            display: none;
            margin-left: 5px;
            text-decoration: line-through;
          }
        }
        &.selected{
          .check-box{
            &:before{
              content: " ";
              display: inline-block;
              margin: 0 auto;
              width: 8px;
              height: 8px;
              position: relative;
              background: $js-buy-trail-color;
              border-radius: 50%;
              top: -2px;

              /*font-family: $font-icon;
              content: $font-icon-check;
              text-align: center;
              font-icon: 24px;*/
            }
          }
        }
        &[data-is-buyable="true"]{
          .stock{
            color: green;
          }
        }
      }
      .attributes-2-inner{
        display: none;
        .item{

        }
      }
    }
  }
  .attributes-2{
    margin-top: 15px;
    .attributes-2-inner{
      display: block;
    }
  }
}