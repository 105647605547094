$font-icon:           "FontAwesome"; 

//FONTAWESOME
$font-icon-menu:      "\f0c9";
$font-icon-delete:    "\f00d";  
$font-icon-down:      "\f107";  
$font-icon-check:     "\f00c";  
$font-icon-info:      "\f129"; 
$font-icon-eye:       "\f06e";  
$font-icon-plus:      "\f067"; 
$font-icon-minus:     "\f068";  
$font-icon-right:     "\f105";
$font-icon-left:      "\f104";
$font-icon-dot:       "\f111";
$font-icon-magnify:   "\f111";
$font-icon-filter:	  "\f0b0";
$font-icon-sorting:	  "\f0dc";
$font-icon-transport: "\f0d1";
$font-icon-home:      "\f015";
$font-icon-facebook:  "\f09a";
$font-icon-instagram: "\f16d";
$font-icon-youtube:   "\f167";
$font-icon-linkedin:  "\f0e1";
$font-icon-search:    "\f002";
$font-icon-cart:      "\f07a";
$font-icon-user:      "\f007";
$font-icon-phone:     "\f095";
$font-icon-position:   "\f0ac";

$font-icon-facebook:  "\f230";
$font-icon-twitter:   "\f081";
$font-icon-pinterest: "\f0d3";