
//CANVAS SIDES
$canvas-background-color:		    $body-bg;
$canvas-width: 					    500px;

//GENERAL
$body-bg:                           white;
$page-padding:					    2%;
$header-is-fixed:                   true;

//CONTENT
$content-link-color:                $secondary-color;
$content-link-weight:               700;

//TOP BAR
$top-bar:						    true;
$top-bar-height:				    30px;
$top-bar-background:			    Black;
$top-bar-color: 				    white;
$top-bar-font-size:				    14px;

//HEADER
$header-height:           		    60px;
$header-height-desktop:   		    80px;
$header-height-desktop-scrolled:    70px;
$header-background:       		    #1f1f1f; //transparentize($primary-color, 0.1);
$header-color:            		    $js-body-font-color;
$header-icon-color:		  		    white;
$header-border-color:	  		    $border-color;
$header-menu-title:		  		    false;
$header-logo-maxheight: 	        42px;
$header-logo-maxheight-desktop:		60px;
$header-logo-maxheight-desktop-scrolled:50px;
$header-logo-maxwidth:       	    200px;
$header-logo-maxwidth-desktop:		400px;
$header-logo-maxwidth-desktop-scrolled:200px;


//SEARCH IN HEADER
$search-header-height:    	        42px; //$header-height-desktop - 50;
$search-header-height-scrolled:  	42px;//$header-height-desktop-scrolled - 20;
$search-header-top:			        ( $header-height-desktop - $search-header-height ) / 2;
$search-header-top-scrolled:		( $header-height-desktop-scrolled - $search-header-height-scrolled ) / 2;
$search-header-width:		        23%;
$search-header-left:		        calc(50% - 310px);
$search-header-left-scrolled:		37%;
$search-header-border-color: 		#DDD;
$search-header-background:	        white;
$search-header-background-desktop:	white;
$search-header-color:               #444;
$search-header-color-desktop:     	#444;
$search-header-button-background: 	$primary-color;//$js-buy-trail-color;
$search-header-icon-color:	        #333;
$search-header-icon-color-desktop:  white;
$search-header-icon-size: 	        14px;
$search-header-icon-size-desktop: 	18px;
$search-header-font-size:			12px;
$search-header-font-size-desktop:	12px;

//CART
$cart-style:                        "DETAILED"; //DETAILED OR ICON
$cart-item-color:				    white;
$cart-item-background:			    $js-buy-trail-color;
$cart-detailed-button-height:       40px;
$cart-detailed-top-position:        19px;
$cart-detailed-margin:              6px;
$cart-detailed-freight-height:      25px;
$cart-detailed-freight-position:    $header-height-desktop - $cart-detailed-top-position - $cart-detailed-button-height - $cart-detailed-margin - $cart-detailed-freight-height;

//VAT SWITCHER
$vat-switcher-background:           transparent;
$vat-switcher-color:                white;
$vat-switcher-selected-background:  #3e3e3e;
$vat-switcher-selected-color:       white;


//MOBILE SIDE NAV
$side-menu-width:           	    280px;
$side-menu-padding-left:    	    4%;
$side-menu-padding-right:   	    4%;
$side-menu-toggle-font-size:        18px;
$side-menu-logo-max-height:		    40px;
$side-menu-icon-width:			    25%;
$side-menu-text-width:			    75%;
$side-menu-background:      	    white;
$side-menu-cat-background:  	    transparent;
$side-menu-border:          	    #DDD;
$side-menu-color:           	    white;
$side-menu-toggle-color:    	    #777;
$side-menu-selected:        	    $js-buy-trail-color;
$side-menu-icon-selected:   	    $side-menu-color;
$side-menu-show-all-link:		    $secondary-color;
$side-menu-level1-background:	    $side-menu-background;
$side-menu-level2-background:	    lighten($side-menu-level1-background, 4%);
$side-menu-level3-background:	    lighten($side-menu-level2-background, 4%);
$side-menu-level4-background:	    lighten($side-menu-level3-background, 4%);
$side-menu-level1-color:		    $js-body-font-color;
$side-menu-level2-color:		    $js-body-font-color;
$side-menu-level3-color:		    $js-body-font-color;
$side-menu-level4-color:		    $js-body-font-color;
$side-menu-level1-border:		    1px solid #EEE;
$side-menu-level2-border:		    1px solid #EEE;
$side-menu-level3-border:		    1px solid #EEE;
$side-menu-level4-border:		    1px solid #EEE;
$side-menu-level1-font-size:	    14px;
$side-menu-level2-font-size:	    14px;
$side-menu-level3-font-size:	    12px;
$side-menu-level4-font-size:	    12px;
$side-menu-level1-height:     	    55px;
$side-menu-level2-height:    	    40px;
$side-menu-level3-height:    	    36px;
$side-menu-level4-height:    	    36px;
$side-menu-level1-padding-left:		0%;
$side-menu-level2-padding-left:		6%;
$side-menu-level3-padding-left:		12%;
$side-menu-level4-padding-left:		18%;

//SEARCH IN MOBILE SIDE NAV
$search-side-menu-height:     		$header-height;
$search-side-menu-border-color: 	#DDD;
$search-side-menu-background:     	Transparent;
$search-side-menu-color:          	#777;
$search-side-menu-font-size:		16px;

//PAGE LIST IN MOBILE SIDE NAV
$page-list-border-color:	        #EEE;
$page-list-color:     		        $js-body-font-color;
$page-list-line-height:		        46px;
$page-list-font-size: 		        14px;


//DESKTOP HEADER NAV
$desktop-nav:				  	    true;
$desktop-nav-height:         	    44px;
$desktop-nav-background:      	    lighten($header-background, 5%);//darken($primary-color, 5%);
$desktop-nav-border:			    $border-color;
$dnav-header-font-size:             20px;
$dnav-l1-padding:				    40px;
$dnav-l1-color:             	    white;
$dnav-l1-background:                $primary-color;
$dnav-l1-hover-color:  			    lighten($primary-color, 5%);
$dnav-l1-background-hover:  	    lighten($dnav-l1-background, 3%);//darken($desktop-nav-background,5%);
$dnav-l1-selected-color:  		    white;
$dnav-l1-selected-background:     	lighten($dnav-l1-background, 15%);
$dnav-l1-selected-hover-color:      white;
$dnav-l1-selected-hover-background: $dnav-l1-selected-background;//lighten($dnav-l1-background, 5%);
$dnav-l1-active-color:			    white;
$dnav-l1-active-background:		    lighten($dnav-l1-background, 10%);
$dnav-l1-active-hover-background:   lighten($dnav-l1-background, 10%);
$dnav-l1-font-size:         	    14px;
$dnav-l1-font-weight:       	    normal;
$dnav-sub-menu-border:			    $border-color;
$dnav-l2-color:         		    #333;
$dnav-l2-background:    		    white;//$dnav-l1-background;
$dnav-l2-border:        		    none;
$dnav-l2-hover-color:  			    $js-primary-link-hover-color;
$dnav-l2-link-background:           white;
$dnav-l2-selected-link-background:  lighten($dnav-l2-link-background, 7%);
$dnav-l2-selected-color:		    #333;
$dnav-l2-selected-hover-color:      #333;//$js-primary-link-hover-color;
$dnav-l2-selected-link-background:  lighten($dnav-l2-link-background, 7%);
$dnav-l2-font-size:     		    15px;
$dnav-l2-font-weight:   		    bold;
$dnav-l3-color:         		    $dnav-l2-color;
$dnav-l3-background:    		    $dnav-l1-background;
$dnav-l3-border:        		    $border-color;
$dnav-l3-hover-color:  			    $js-primary-link-hover-color;
$dnav-l3-selected-color:		    white;//$secondary-color;
$dnav-l3-selected-hover-color:      white;//$js-primary-link-hover-color;
$dnav-l3-selected-text-decoration:  underline;
$dnav-l3-font-size:     		    14px;
$dnav-l3-font-weight:   		    normal;
$dnav-show-all-link: 			    $secondary-color;


//FOOTER
$footer-background:                 $secondary-color;
$footer-color:                      white;
$jetshop-branding-background:       $secondary-color;


//GRID ROW
$grid-row-margin:                   2;























