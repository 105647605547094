.sort-wrapping{
	display: none !important;
}
.category-header-title .showing-amount{
	@media #{$large-up}{
		display: none;
	}	
}
#sorting-custom-select{
	overflow: hidden;
	padding: 10px 0;
	border-top: 1px solid $border-color;
	.styled-select{
		float: right;
		width: 270px;
	}
	@media #{$medium-down}{
		.styled-select{		
			width: 100%;
			float: none;
		}		
	}	
}

#sorting-custom-wrapper{
	border-top: 1px solid $border-color;
	padding: 20px 0;
	border-bottom: 1px solid $border-color;
	margin-bottom: 10px;
	.title{
		margin-right: 10px;
		color: #BBB;
		@media #{$medium-down}{
			text-align: left;
			display: block;
			margin-bottom: 10px;
		}
	}
	.items{
		display: inline-block;
	}
	.item{
		padding: 0 10px;
		>span{
			display: inline-block;
		}
		.input-box{
			display: none;		
		}
		&.selected{
			font-weight: bold;
			@media #{$medium-down}{
				font-weight: normal;
			}
		}
	}
	.showing-amount{
		display: none;
		float: right;
		font-size: 12px;
		.amount{
			margin-right: 5px;
		}
	}
	a.showall {
		display: none !important;
		float: right;
		margin-left: 10px;
	    color: $js-body-font-color;
	    font-weight: normal;
	    text-align: right;
	    cursor: pointer;
	}
	&.left-area {
		@media #{$medium-down}{
			display: block;
			position: fixed;
			background: white;
			overflow: auto;
			top: $header-height + $filter-mobile-buttons-height;
			width: 300px;
			height: 100%;
			z-index: 9998;
			right: -300px;
			padding-top: 0;
			padding-bottom: 50px + $header-height + $filter-mobile-buttons-height;
			-webkit-transition: right .3s;
			-moz-transition: right .3s;
			-ms-transition: right .3s;
			-o-transition: right .3s;
			transition: right .3s;
			border-left: 1px solid $border-color;
			border-top: 1px solid $border-color;
			.title {
				padding: 20px 0 45px 0;
				margin: 0 5%;
				text-align: center;
				border-bottom: 1px solid $border-color;
				font-size: 1.2rem;
				color: $js-body-font-color;
				text-transform: none;
				display: block;
				font-weight: bold;
			}
			> .close-sorting {
				display: none;
				@media #{$medium-down}{
					cursor: pointer;
					display: block;
					position: absolute;
					top: 11px;
					right: 17px;
					&:before {
						font-family: $font-icon;
						content: $font-icon-delete;
						font-size: 28px;
					}
				}
			}
			.items {
				display: block;
				margin: 0 5%;
			}
			.item {
				padding: 10px 0;
				border-bottom: 1px solid $border-color;
				display: block;
				&:last-child {
					border-bottom: none;
				}
				.input-box {
					display: block;
					float: right;
				}
				/*	float: right;
                    width: 18px;
                    height: 18px;
                    border: 1px solid $border-color;
                    margin-right: 5px;
                    text-align: center;
                    line-height: 18px;
                    vertical-align: text-bottom;
                    position: relative;
                    top: -1px;
                }
                &.selected{
                    font-weight: normal;
                    .input-box:after{
                        font-size: 12px;
                        font-family: $font-icon;
                        content: $font-icon-check;
                        color: $js-buy-trail-color;
                        position: relative;
                        top: -2px;
                    }
                }*/
			}
		}
	}
}

html.sorting-open{
	#sorting-custom-wrapper{
		right: 0;
	}
	#side-menu-overlay{
		display: block;
		top: $header-height + $filter-mobile-buttons-height;
	}	
}

.category-header-title{
	.showing-amount{
		display: block !important;
		font-size: 12px;
		margin: 5px 0 15px;
		color: #888;
		.amount{
			margin-right: 5px;
		}		
		@media #{$large-up}{
			display: none !important;
		}		
	}
}
