//
//  Start Page
//

.page-default {
  #path-nav {
    display: none;
  }
}

.page-default{
  #left-area{
    display: none;
  }
  #main-area{
    width: 100%;
  }
  .startpage-header{
    display: none;
  }
  #sub-nav-wrapper{
    display: none;
  }
}


.page-default .startpage-image {
  text-align: center;
  //margin-bottom: 12px;
}

.page-default .startpage-text {
  //margin-bottom: 4px;
}

//
//  Hide the div that holds the HTML-definitions for sliders
//
#switcher-definitions {
  display: none;
}


#startpage_list {
  ul {
    li {
      .product-info {
        //text-transform: uppercase;
        @include product-name-h3
      }
      .product-wrapper {
        .product-image {
          padding-top: $js-startpage-wrapper-image-wrapper-height;
        }
      }
    }
  }
}

//
//  Small
//
@media #{$small-only} {
  @include calculate-item-width(1, $js-product-wrapper-margin-right, "#startpage_list ul.items-1> li");
  @include calculate-item-width(1, $js-product-wrapper-margin-right, "#startpage_list ul.items-2> li");
  @include calculate-item-width(1, $js-product-wrapper-margin-right, "#startpage_list ul.items-3> li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right, "#startpage_list ul.items-4> li");
}

//
//  Medium
//
@media #{$medium-only} {
  @include calculate-item-width(1, $js-product-wrapper-margin-right, "#startpage_list ul.items-1> li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right, "#startpage_list ul.items-2> li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right, "#startpage_list ul.items-3> li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right, "#startpage_list ul.items-4> li");
  #startpage_list .items-1 .product-wrapper .product-image {
   // padding-top: ($js-startpage-wrapper-image-wrapper-height / 2)
  }
}

//
//  Large
//
@media #{$large-only} {
  @include calculate-item-width(1, $js-product-wrapper-margin-right, "#startpage_list ul.items-1> li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right, "#startpage_list ul.items-2> li");
  @include calculate-item-width(3, $js-product-wrapper-margin-right, "#startpage_list ul.items-3> li");
  @include calculate-item-width(4, $js-product-wrapper-margin-right, "#startpage_list ul.items-4> li");
  #startpage_list .items-1 .product-wrapper .product-image {
    //padding-top: ($js-startpage-wrapper-image-wrapper-height / 4)
  }
}

//
//  X-Large
//
@media #{$xlarge-only} {
  @include calculate-item-width(1, $js-product-wrapper-margin-right, "#startpage_list ul.items-1> li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right, "#startpage_list ul.items-2> li");
  @include calculate-item-width(3, $js-product-wrapper-margin-right, "#startpage_list ul.items-3> li");
  @include calculate-item-width(4, $js-product-wrapper-margin-right, "#startpage_list ul.items-4> li");
  #startpage_list .items-1 .product-wrapper .product-image {
    //padding-top: ($js-startpage-wrapper-image-wrapper-height / 5);
  }
}

//
//  XX-Large
//
@media #{$xxlarge-only} {
  @include calculate-item-width(1, $js-product-wrapper-margin-right, "#startpage_list ul.items-1 li");
  @include calculate-item-width(2, $js-product-wrapper-margin-right, "#startpage_list ul.items-2 li");
  @include calculate-item-width(3, $js-product-wrapper-margin-right, "#startpage_list ul.items-3 li");
  @include calculate-item-width(4, $js-product-wrapper-margin-right, "#startpage_list ul.items-4 li");
  #startpage_list .items-1 .product-wrapper .product-image {
    //padding-top: ($js-startpage-wrapper-image-wrapper-height / 5);
  }
  #startpage_list .items-2 .product-wrapper .product-image {
    //padding-top: ($js-startpage-wrapper-image-wrapper-height / 1.4);
  }
}

