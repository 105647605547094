//HEROAREA
#hero-area{
  margin-bottom: 3%;
  overflow: hidden;
  >div {
    position:relative;
    &:nth-of-type(1) {
      width: 66.8%;
      float: left;
      @media #{$medium-down}{
        width: 100%;
        float: none;
        margin-bottom: 1%;
      }
      @media #{$small-only}{
        width: 100%;
        margin-bottom: 1.5%;
      }
    }
    &:nth-of-type(2),&:nth-of-type(3){
      width: 32.6%;
      float: right;
      @media #{$medium-down}{
        width: 49.5%;
      }
      @media #{$small-only}{
        width: 100%;
      }
    }
    &:nth-of-type(2) {
      margin-bottom: 0.8%;
      @media #{$medium-down}{
        float: left;
        margin-bottom: 1%;
      }
      @media #{$small-only}{
        margin-bottom: 1.5%;
      }
    }
    .custom-item-text{
      position: absolute;
      width: 70%;
      right: 0;
      bottom: 10%;
      text-align: center;
      >*, >*:hover{
        color: white;
      }
    }
  }
}

//HEROAREA WITH SLIDER
#hero-area-slider{
  margin-bottom: 2%;
  overflow: hidden;
  .startpage-custom-item {
    display: block;
    position: relative;
  }
  .slider-wrapper {
    width: 66.8%;
    float: right;
    margin: 0;
    @media #{$medium-down}{
      width: 100%;
      float: none;
      margin-bottom: 1%;
    }
    @media #{$small-only}{
      width: 100%;
      margin-bottom: 1.5%;
    }
    .slick-slide{
      margin: 0;
    }
    .slick-prev:before, .slick-next:before{
      color: white;
      font-size: 42px;
    }
  }
  .image-box-wrapper{
    width: 32.6%;
    float: left;
    @media #{$medium-down}{
      width: 100%;
      >div{
        width: 49.5%;
        float: left;
        &:last-child{
          float: right;
        }
      }
    }
    @media #{$small-only}{
      >div {
        width: 100%;
        float: none;
      }
    }
    div:first-child{
      margin-bottom: 2.4%;
      @media #{$medium-down}{
        float: left;
        margin-bottom: 1%;
      }
      @media #{$small-only}{
        margin-bottom: 1.5%;
      }
    }
  }
  .custom-item-text{
    position: absolute;
    width: 70%;
    right: 0;
    bottom: 10%;
    text-align: center;
    >*, >*:hover{
      color: white;
    }
  }
  .text-overlay-festool{
    .custom-item-text{
      position: absolute;
      width: 100%;
      right: 0%;
      bottom: 0%;
      background: rgba(0, 0, 0, 0.6);
      .text-overlay-content{
        height: 30px;
        margin: 0 10px;
        float: left;
        display: inline-block;
        >*, >*:hover{
          color: white;
        }
        @media #{$small-only} {
          h4{
            font-size: 16px;
          }
        }
      }
      .sale-button{
        position: absolute;
        background: $festool-blue;
        color: $festool-green;
        border: none;
        bottom: 30px;
        right: 0;
      }
    }
  }
  .text-overlay-schneider{
    .custom-item-text{
      position: absolute;
      width: 100%;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.6);
      .text-overlay-content {
        height: 30px;
        margin: 0 10px;
        float: left;
        display: inline-block;
        > *, > *:hover {
          color: white;
        }
        @media #{$small-only} {
          h4{
            font-size: 16px;
          }
        }
      }
    }
    .sale-button{
      position: absolute;
      background: black;
      color: $schneider-yellow;
      border: none;
      bottom: 30px;
      right: 0;
    }
  }

  .text-overlay{
    .custom-item-text{
      position: absolute;
      width: 50%;
      right: 10%;
      bottom: 20%;
      //text-align: center;
      //background: rgba(00, 00, 00, 0.90);
      //border: 2px solid rgba(255, 216, 19, 0.8);
      >*, >*:hover{
        color: white;
      }
    }
    .sale-button{
      background: black;
      color: $schneider-yellow;
      border: none;
    }
  }
}

//START SLIDER
#start-slider{
  .custom-item-link{
    display: block;
    position: relative;
  }
  .slick-slide{
    margin: 0;
  }
  .slick-prev:before, .slick-next:before{
    color: white;
    font-size: 42px;
  }
  .custom-item-text{
    position: absolute;
    width: 70%;
    right: 0;
    bottom: 10%;
    text-align: center;
    >*, >*:hover{
      color: white;
    }
  }

}

//GENERAL
.page-default{
  .startpage-text{
    margin: 0;
  }
}

#startpage_list{
  ul{
    li{
      margin-bottom: 2%;
    }
  }

  .header{
    padding-top: 10px;
    >*:last-child{
      margin-bottom: 0;
    }
  }


  //ITEMS
  .startpage-custom-item{
    position: relative;
    .custom-item-image{
      width: 80%;
    }
    &.text-under{
      text-align: center;
      padding: 5px 5px 15px ;
      border: 1px solid $border-color ;
      .custom-item-text{
        display: block;
        padding-top: 10px;
        >*:first-child{
          margin-top: 0;
        }
        >*:last-child{
          margin-bottom: 0;
        }
      }
    }
    &.text-overlay{
      .custom-item-text{
        >*, >*:hover{
          color: white;
        }
        position: absolute;
        bottom: 0%;
        right: 0%;
        width: 100%;
        text-align: right;
        background: rgba(00, 00, 00, 0.70);
        padding: 10px 50px 10px 0;
      }
    }
  }
  .list-category{
    padding: 0;
  }
}


//CUSTOM CLASSES
.express-box{
  background: white;
  border: 1px solid $border-color;
  overflow: hidden;
  .delivery-box{
    position:relative;
    float: left;
  }
}

//TEXT IN BOTTOM
.bottom-text{
  background: white;
  border: 1px solid $border-color;
  padding: 25px;
  h3{
    margin: 5px 0 25px 0;
  }
  p, .trending-brands{
    max-width: 900px;
    margin: 0 auto;
  }
  .trending-brands{
    max-width: 1000px;
  }
}

//TRENDING BRANDS
.trending-brands{
  .grid-row{
    margin-top: add-unit($js-product-wrapper-margin-right-large-up, "%");

    div{
      a{
        padding: 30px;
        line-height: 60px;
        border: 1px solid $border-color;
        display: block;
      }
      img{
        display: inline-block !important;
        max-width: 75%;
        max-height: 50px;
      }
    }
  }
}


//SALE BUTTON
.sale-button{
  background: $js-buy-trail-color;
  padding: 2px 20px;
  display: inline-block;
  border: 1px solid darken($js-buy-trail-color, 10%);
  color: white;
  &:hover{
    color: white;
  }
  a{
    color: white;
  }
  @media #{$small-only}{
    padding: 5px 10px;
  }
}


.big-banner{
  h1{
    margin: 20px 0 0 20px;
    float: left;
    color: $secondary-color;
    max-width: 460px;
    text-align: left;
    line-height: 2em;
  }
  h2{
    margin: 20px 20px 0 0;
    float: right;
    line-height: 2em;
  }
  @media #{$small-only}{
    h1, h2{
      line-height: 1em;
    }
  }
}

.delivery-box{
  background: $secondary-color;
  color: white;
  position:absolute;
  bottom: 0;
  left: 0;
  width: 50%;
  border-top-right-radius: 70px;
  padding: 30px 0;
  p{
    text-align: left;
    margin-left: 40%;
    margin-bottom: 0;
    font-size: 1.1rem;
    font-weight: bold;
  }
  &:before{
    position: absolute;
    top: 6%;
    left: 15%;
    font-size: 60px;
    font-family: $font-icon;
    content: $font-icon-transport;
  }
  @media #{$small-only}{
    display: none;
  }
}

.start-page-border{
  width: 100%;
  border-top: 4px solid $festool-blue;
}

