
//MOBILE SIDEMENU
#side-menu {
	display: block !important;
	padding: 0;
	position: fixed;
	width: $side-menu-width;
	max-width: 90%;
	top: 0; // $header-height;
	//padding-bottom: $header-height*2;
	height: 100%;
	left: -$side-menu-width;
	overflow: auto;
	background: $side-menu-background;
	border-right: 1px solid $side-menu-border;	
    -webkit-transition: left .3s;
    -moz-transition: left .3s;
    -ms-transition: left .3s;
    -o-transition: left .3s;
    transition: left .3s;

    @media all and (min-width: $js-max-page-width) {
		//left: calc(50% - #{($js-max-page-width/2 + $side-menu-width)};
    }

	ul{
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	#side-menu-logo{
		display: none;
		img{
			margin: ($header-height - $side-menu-logo-max-height)/2 $side-menu-padding-right ($header-height - $side-menu-logo-max-height)/2 $side-menu-padding-left;	
			max-height: $side-menu-logo-max-height;
		}
	}

	#side-menu-close{
		position: absolute;
		width: 20px;
		height: 20px;
    	right: 7px;
    	top: 0px;
		font-size: 22px;
		&:after{
			content: $font-icon-delete;
			font-family: $font-icon;
		}
	}

    //SEARCH
	.custom-search-wrapper{
		width: 100%;
		height: $search-side-menu-height + 1px;
		border-bottom: 1px solid $search-side-menu-border-color;
		background: $search-side-menu-background;		
		input {
			height: $search-side-menu-height;
			border: none;
			width: 80%;
			padding-left: $side-menu-padding-left;
			display: block; 
			float: left;
			font-size: $search-side-menu-font-size;
			color: $search-side-menu-color;		
		}
		a.button {
			margin: 0;
			padding: 0;
			text-align: center;
			height: $search-side-menu-height;
			line-height: $search-side-menu-height;
			background: none;
			width: 20%;
			display: block; 
			float: left;
			color: $search-side-menu-color;		
			&:before{
				font-family: $font-icon;
				content: "\f002";
				font-size: 17px;
				color: #777;
			}
		}
	}	

	//VAT SWITCH
	.side-menu-vat-wrapper{
		width: 100%;
		padding: 10px;
		.vat-switcher-button-wrapper{
			width: 50%;
			display: inline-block;
			a{
				display: inline-block;
				width: 100%;
				padding: 5px 15px !important;
				background: $vat-switcher-background !important;
				color: $vat-switcher-selected-background !important;
				border: 1px solid $vat-switcher-selected-background ;
				&.selected{
					background: $vat-switcher-selected-background !important;
					color: $vat-switcher-selected-color !important;
					border: 1px solid $vat-switcher-selected-background;

				}
				&.selected:before{
					font-family: $font-icon;
					content: $font-icon-check ;
					font-size: 10px;
					margin-right: 5px;
				}
			}
		}
	}

	//MOBILE CAT NAV
	ul.category-navigation{
		margin-top: 2em;
		padding: 0 $side-menu-padding-right 0 $side-menu-padding-left;
		overflow: hidden;
		clear: both;
		//GENERAL
		li{
			clear: both;
			padding: 0;
			overflow: hidden;	
			a, span{
				display: block;
				padding: 0 $side-menu-padding-right 0 0;
				color: $side-menu-color;
			}
			>.icon{
				width: $side-menu-icon-width;
				float: right;
				padding: 0;
				border-right: none;	
			}
			a{
				width: $side-menu-text-width;
				float: left;
				text-align: left;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;								
				&.icon{
					&:before{
						font-size: $side-menu-toggle-font-size;
						color: $side-menu-toggle-color;
						content: $font-icon-plus;
						font-weight: normal !important;
						display: block;
						text-align: right;						
					}
				}
				&:hover{
					color: $side-menu-selected;
					&:before{
						color: $side-menu-selected;
					}
				}
			}		
			ul{
				clear: both;				
			}
			&.has-subcategories{
				>ul>li{
					display: none;
				}
			}
			&.active{
				>ul>li{
					display: block;
				}
				&.selected, &:not(.open){
					>a:not(.icon){
						color: $side-menu-selected;
						font-weight: bold;
						/*&:after{
							font-family: "FontAwesome";
							content: "\f053";
							font-size: 12px;
							margin-left: 5px;
							color: $side-menu-icon-selected;
							position:relative;
							top: -1px;
						}*/
					}
				}
			}
			&.open{
				width: 100%;
				height: calc(100% - 55px);
				z-index: 2;
				>a.icon{
					//width: 100%;
					//float: none;
					&:before{
						text-align: right;
						content: $font-icon-minus;
					}
				}
				.open{
					top: 7px;
				}
			}
			&:first-child{

			}
			&.show-all-link a{
				color: $side-menu-show-all-link !important;
			}													
		}				
		//LEVEL 1
		>li{
			background: $side-menu-level1-background;
			border-bottom: $side-menu-level1-border;	
			>a:not(.icon){
				padding-left: $side-menu-level1-padding-left;
			}			
			>a{
	 			font-size: $side-menu-level1-font-size;
				height: $side-menu-level1-height;
				line-height: $side-menu-level1-height;
				//font-weight: normal;
				color: $side-menu-level1-color; 
			}
			&.open{
				width: 100% - $side-menu-padding-left - $side-menu-padding-right;				
			}
			//Level2
			>ul>li{
				background: $side-menu-level2-background;
				border-bottom: $side-menu-level2-border;
				&:last-child{
					border: none;
				}
				>a:not(.icon){
					padding-left: $side-menu-level2-padding-left;
				}
				>a{
					font-size: $side-menu-level2-font-size;
					height: $side-menu-level2-height;
					line-height: $side-menu-level2-height;					
					//font-weight: normal;
					color: $side-menu-level2-color;				
				}			
			}

			//Level3
			>ul>li>ul>li{
				background: $side-menu-level3-background;
				border-bottom: $side-menu-level3-border;
				&:last-child{
					border: none;
				}
				>a:not(.icon){
					padding-left: $side-menu-level3-padding-left;
				}
				>a{
					font-size: $side-menu-level3-font-size;
					height: $side-menu-level3-height;
					line-height: $side-menu-level3-height;					
					//font-weight: normal;
					color: $side-menu-level3-color;					
				}
																
			}

			//Level4
			>ul>li>ul>li>ul>li{
				background: $side-menu-level4-background;
				border-bottom:$side-menu-level4-border;									
				>a:not(.icon){
					padding-left: $side-menu-level4-padding-left;
				}
				>a{
					font-size: $side-menu-level4-font-size;
					height: $side-menu-level4-height;
					line-height: $side-menu-level4-height;					
					//font-weight: normal;
					color: $side-menu-level4-color;			
				}																		
			}							
		}																				
	}

	#channel-selector-dropdown span{
		font-size: $side-menu-level2-font-size;
	}	

	//PAGE LIST
	.page-list{
		padding-top: 20px;
		padding-bottom: 10px;
		>*{
			text-align: left !important;
			display: block;
			margin-left: $side-menu-padding-left;
			margin-right: $side-menu-padding-right;			
			color: $page-list-color;
			line-height: $page-list-line-height;
			font-size: $page-list-font-size;
			border-bottom: 1px solid $page-list-border-color;
			&:before{

			}
			&:hover{
				color: $page-list-color;
			}
			&.my-pages:before{
				//font-family: $font-icon;
				//content: "\f007";
				//margin-right: 5px;
			}
		}
		.login-text, .mypages-text{
			color: $js-primary-link-color;
		}
	}
}

#side-menu .category-navigation{
	li.lv4, li.lv5, li.lv6{
		display: none !important;
	}
	li.lv3{
		.icon{
			display: none;
		}
	}
}