html.page-listproducts {
  .category-header-wrapper {
    @media #{$medium-up}{
      margin-bottom: 0;
    }
  }
  .category-header-subtitle, .category-header-title {
    text-align: left;

  }
  .category-header-title {
    h1 {
      margin-bottom: 0;
    }
  }
  .category-page-image{
    display: none;
    margin: 0;
  }
  .category-header-subtitle{
    clear: left;
    padding-top: 10px;
  }
}



//LISTING
.category-page-wrapper, .releware-recommendation-wrapper, #startpage_list, .list-category {
  ul {
    li {
      margin-bottom: add-unit($js-product-wrapper-margin-right-large-up, "%");
      @media #{$medium-down}{
        margin-bottom: add-unit($js-product-wrapper-margin-right, "%");
      }
      .product-image {

      }

      .product-list-description {
        margin: 2px 0 5px;
      }

      .product-name {

      }

      .product-list-article-number span {

      }

      .product-price {
        >div{
          display: inline-block;
          &:last-child{
            margin-left: 5px;

          }
          &:first-child{
            margin-left: 0;
          }
        }
        .price-amount {

        }
      }

    }

  }
}

//BIG IMAGE
html.category-page-big-image{
  #content-wrapper {
    padding-top: 15px;
  }
  .category-big-img {
    float: none;
    max-width: 1400px; //9999px;
    width: 100%;
    margin: $header-height auto 0 auto;
    img {
      width: 100%;
    }
    @media #{$large-up}{
      margin: $header-height + $desktop-nav-height + $top-bar-height + 20px auto 0 auto;
    }
    @media #{$xxlarge-only}{
      //max-height: 450px;
      //overflow: hidden;
      img{
       // position: relative;
        //bottom: 30px;

      }
    }
  }
}



