.product-wrapper.quantity-selector{
  a.buy-button, a.buy-button:hover{
    width: 100%;
    float: none;
  }
  a.button-info{
    width: 100%;
  }
  .quantity-wrapper{
    width: 50%;
    float: left;
    margin-bottom: 5px;
  }
  .product-buttons{
    width: 100%;
    float: right;
    clear: none;
  }
}

//NO BUY BUTTON
.product-wrapper.no-buy-button{
  a.button-info, a.button-info:hover{
    width: 100%;
    float: none;
  }
}

.product-buttons{
  clear: both;
  a{
    padding: 0 !important;
    height: 30px !important;
    line-height: 30px !important;
  }
}

//QUANTITY WRAPPER
.product-wrapper .quantity-wrapper{
  text-align: center;
  .quantity-button{
    width: 35%;
    height: 30px;
    display: inline-block;
    border: 1px solid #CCC;
    background: #EEE;
    cursor: pointer;
    float: left;
    &:before{
      line-height: 30px;
      font-family: $font-icon;
      vertical-align: middle;
    }
    &.plus{
      float: right;
      border-left: none;
      &:before {
        content: $font-icon-plus;
      }
    }
    &.minus{
      border-right: none;
      &:before {
        content: $font-icon-minus;
      }
    }
  }
  input{
    float: left;
    text-align: center;
    padding: 0;
    border: 1px solid #CCC;
    width: 30%;
    height: 30px;
    display: inline-block;
    vertical-align: bottom;
  }
}

.custom-tab-wrapper {
  ul {
    padding-left: 20px;
    list-style-type: disc;
    margin-bottom: 15px;
    li {

    }
  }
}