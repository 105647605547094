@import "subcategories-settings";

#subcategories-spinner {
  height: $subcategories-spinner-height;
  text-align: center;
  color: #888888;
  padding-top: 50px;
  display: none;
}
.subcategories-wrapper{
  width: 100%;
  display: inline-block;
  margin-bottom: -6px;
  #subcategories-placeholder{
    background: white;
    max-width: 1400px;
    margin: 0 auto;
    display: block;
    //padding: 0 $page-padding;
    @media #{$xxlarge-up}{
      padding: 0 ;
    }
    #subcategories-container {
      padding: 12px 20px;
      margin-bottom: 0px;
      transition: all .3s ease .15s;
      border-bottom: 4px solid $festool-blue;

      .subcategories-item {
        float: left;
        margin-bottom: 12px;
        border: 1px solid $border-color;
        padding: 5px;
        //margin-top: 12px;
        //margin-right: 10px;

        .subcategories-link {
          text-decoration: none;
          &:hover {
            text-decoration: none;
          }
        }
        .subcategories-image {
          text-align: center;
          margin-bottom: 12px;
          img{
            max-height: 150px;
          }
        }
        .subcategories-name {
          text-align: center;
          font-size: 16px;
          font-weight: 800;
          word-break: break-word;
        }
      }

      &.subcategories-has-no-images {
        .subcategories-name {
          //background-color: #efefef;
          border: 1px solid #adadad;
          padding: 5px 10px;
          background: #fafafa;
          &:hover {
            background-color: lighten(#fafafa, 10%);
          }
        }
      }
    }
  }
}



// FULL WIDTH



/// KOMMENTERA BORT MEDIA OM DU SKALL ÅTERSTÄLLA TILL SUBCATEGORIES LIKT FESTOOLS

//
//  Medium
//
@media #{$medium-only} {
  @include calculate-item-width($subcategories-total-items-medium, $subcategories-item-margin-right, $subcategories-item-selector);
}

//
//  Small
//
@media #{$small-only} {
  @include calculate-item-width($subcategories-total-items-small, $subcategories-item-margin-right, $subcategories-item-selector);
}

//
//  Large
//
@media #{$large-only} {
  @include calculate-item-width($subcategories-total-items-large, $subcategories-item-margin-right-large-up, $subcategories-item-selector);
}

//
//  X-Large
//
@media #{$xlarge-only} {
  @include calculate-item-width($subcategories-total-items-xlarge, $subcategories-item-margin-right-large-up, $subcategories-item-selector);
}

//
//  XX-Large
//
@media #{$xxlarge-only} {
  @include calculate-item-width($subcategories-total-items-xxlarge, $subcategories-item-margin-right-large-up, $subcategories-item-selector);
}