
//PRODUCT PAGE

html.page-showproduct{

	.product-images{
		position: relative;
		.slick-thumbs{
			width: 10%;
			float: left;
			li{
				margin-bottom: 5px;
			}
		}
		.slick-slider{
			width: 89%;
			float: left;
			margin-left: 1%;
			.slick-slide{
				margin: 0 5px;
				position:relative;
			    a:hover:after{
			      font-family: $font-icon;
			      content:$font-icon-search;
			      position: absolute;
			      top: 10px;
			      right: 10px;
			      font-size: 20px;
			    }
			}
			.slick-dots{
				display: none !important;
			}
		}

		@media #{$medium-down}{
			.slick-thumbs{
				display: none;
			}
			.slick-slider{
				width: 100%;
				float: none;
				margin-left: 0px;
			}
		}
	}
}