//
//  Foundation Settings
//
$include-xl-html-block-grid-classes : true;
$include-html-global-classes: true;
$global-radius: 3px;
$global-rounded: 1000px;
$base-font-size: 100%;
$list-side-margin: 0;


//
//  Colors
//
$white       : #FFFFFF;
$ghost       : #FAFAFA;
$snow        : #F9F9F9;
$vapor       : #F6F6F6;
$white-smoke : #F5F5F5;
$silver      : #EFEFEF;
$smoke       : #EEEEEE;
$gainsboro   : #DDDDDD;
$iron        : #CCCCCC;
$base        : #AAAAAA;
$aluminum    : #999999;
$jumbo       : #888888;
$monsoon     : #777777;
$steel       : #666666;
$charcoal    : #555555;
$tuatara     : #444444;
$oil         : #333333;
$jet         : #222222;
$black       : #000000;
$red         : #b90000;
$festool-blue: #0e4c6f;
$festool-green:#3fcc1e;
$schneider-yellow: #e6e110;
// Use function grey-scale(1) to grey-scale(19) to get greyscale ramp

$primary-color: $festool-blue;
$secondary-color: #5f5d5d;//#323232;
$border-color: #eee;
$alert-color: #c60f12; // Used by error page
$success-color: #48b07a;
$warning-color: #f08a24;
$info-color: #a0d3e8;


$body-bg: #444;
$js-body-font-color: #333;
$js-buy-trail-color: #357c32;

$js-price-discount-color: #c60f12;
$js-primary-link-color: $primary-color;
$js-primary-link-hover-color: darken($js-primary-link-color,20);
$js-header-background-color: #eef1f2;
$button-bg-color: $primary-color; // This is a Foundation setting

////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Main areas and layout
//
$js-min-page-width: 320px;
$js-max-page-width: 1400px;
$js-left-area-width: 20%;
$js-left-area-padding-right: 20px;
$js-main-area-width-2-col: 80%;
$js-main-area-width-3-col: 60%;
$js-right-area-width: 20%;
$js-page-padding-left: 20px;
$js-page-padding-right: 20px;
$js-main-area-padding-left: 20px;
$js-main-area-padding-right: 20px;
$js-main-area-medium-down-padding-left: 5px;
$js-main-area-medium-down-padding-right: 5px;
$js-standard-page-max-width: 920px;

//
//  Logo
//
$js-logo-wrapper-max-width: 206px; // Actual file-width divided by 2;
$js-logo-wrapper-margin: 18px 20px 18px $js-page-padding-left;
$js-logo-wrapper-margin-medium: 18px 20px 18px $js-page-padding-left;
$js-logo-wrapper-margin-small: 18px 0; // Centered logo on small

//
//  Fonts & Typography
//
$font-family-sans-serif: "Lato", sans-serif;
$font-family-serif: $font-family-sans-serif;
$font-family-monospace: Consolas, "Liberation Mono", Courier, monospace;
$font-family-description: $font-family-sans-serif;
$font-family-header: $font-family-sans-serif;

//  Controller for fonts
$body-font-family: $font-family-sans-serif;
$paragraph-font-family: $font-family-sans-serif;
$button-font-family:  $font-family-sans-serif;
$js-description-font-family: $font-family-serif;

$font-transform-header: Normal;
$font-weight-normal: normal;
$font-weight-bold: bold;
$js-body-font-size: 14px; // Has to be PX, used in calculations
$js-description-font-size: 1.1em;

// We use these to control header font styles
$header-font-family: $font-family-header;
$header-font-weight: $font-weight-bold;
$header-font-style: normal;
$header-font-color: grey-scale(16);
$header-line-height: 1.4;
$header-top-margin: .2rem;
$header-bottom-margin: .5rem;
$header-text-rendering: optimizeLegibility;

// We use these to control header font sizes
$h1-font-size: rem-calc(28);
$h2-font-size: rem-calc(24);
$h3-font-size: rem-calc(20);
$h4-font-size: rem-calc(16);
$h5-font-size: rem-calc(14);
$h6-font-size: 1rem;

// We use these to control header size reduction on small screens
$h1-font-reduction: rem-calc(5);
$h2-font-reduction: rem-calc(5);
$h3-font-reduction: rem-calc(5);
$h4-font-reduction: rem-calc(5);
$h5-font-reduction: 0;
$h6-font-reduction: 0;

// We use these to style paragraphs
$paragraph-font-weight: $font-weight-normal;
$paragraph-font-size: 0.875rem; // 14px
$paragraph-line-height: 1.6;
$paragraph-margin-bottom: rem-calc(12);
$paragraph-aside-font-size: rem-calc(14);
$paragraph-aside-line-height: 1.35;
$paragraph-aside-font-style: italic;
$paragraph-text-rendering: optimizeLegibility;

// We use these to style anchors
$anchor-text-decoration: none;
$anchor-text-decoration-hover: none;
$anchor-font-color: $js-primary-link-color;
$anchor-font-color-hover: $js-primary-link-hover-color;

// These control how subheaders are styled.
$subheader-line-height: 1.4;
$subheader-font-color: scale-color($header-font-color, $lightness: 35%);
$subheader-font-weight: $font-weight-normal;
$subheader-top-margin: .2rem;
$subheader-bottom-margin: .5rem;

// A general <small> styling
$small-font-size: 60%;
$small-font-color: scale-color($header-font-color, $lightness: 35%);

// We use these to style <code> tags
$code-color: $oil;
$code-font-family: $font-family-monospace;
$code-font-weight: $font-weight-normal;
$code-background-color: scale-color($secondary-color, $lightness: 70%);
$code-border-size: 1px;
$code-border-style: solid;
$code-border-color: scale-color($code-background-color, $lightness: -10%);
$code-padding: rem-calc(2) rem-calc(5) rem-calc(1);

// We use these to style the <hr> element
$hr-border-width: 1px;
$hr-border-style: solid;
$hr-border-color: $gainsboro;
$hr-margin: rem-calc(20);

// We use these to style lists
$list-font-family: $body-font-family;
$list-font-size: $js-body-font-size;
$list-line-height: $paragraph-line-height;
$list-margin-bottom: $paragraph-margin-bottom;
$list-style-position: outside;
$list-side-margin: 1.1rem;
$list-ordered-side-margin: 1.4rem;
$list-side-margin-no-bullet: 0;
$list-nested-margin: rem-calc(20);
$definition-list-header-weight: $font-weight-bold;
$definition-list-header-margin-bottom: .3rem;
$definition-list-margin-bottom: rem-calc(12);

// We use these to style blockquotes
$blockquote-font-color: scale-color($header-font-color, $lightness: 35%);
$blockquote-padding: rem-calc(9 20 0 19);
$blockquote-border: 1px solid $gainsboro;
$blockquote-cite-font-size: rem-calc(13);
$blockquote-cite-font-color: scale-color($header-font-color, $lightness: 23%);
$blockquote-cite-link-color: $blockquote-cite-font-color;

// Acronym styles
$acronym-underline: 1px dotted $gainsboro;

// We use these to control padding and margin
$microformat-padding: rem-calc(10 12);
$microformat-margin: rem-calc(0 0 20 0);

// We use these to control the border styles
$microformat-border-width: 1px;
$microformat-border-style: solid;
$microformat-border-color: $gainsboro;

// We use these to control full name font styles
$microformat-fullname-font-weight: $font-weight-bold;
$microformat-fullname-font-size: rem-calc(15);

// We use this to control the summary font styles
$microformat-summary-font-weight: $font-weight-bold;

// We use this to control abbr padding
$microformat-abbr-padding: rem-calc(0 1);

// We use this to control abbr font styles
$microformat-abbr-font-weight: $font-weight-bold;
$microformat-abbr-font-decoration: none;

//
//  Buttons
//
$js-button-font-size: $js-body-font-size;
$js-button-font-weight: 700;
$js-button-padding: 6px 12px;
$js-button-color: white;
$js-button-background-color: $primary-color;
$js-button-letter-spacing: 0;
$js-button-text-transform: uppercase;
$js-button-border-radius: 0;
$js-button-border-width: 0;
$js-button-border-color: transparent;
$js-button-hover-darken-factor: 10%;
$js-button-checkout-icon-size: 16px;
$js-button-disabled-background-color: #888888;
$js-button-disabled-border-color: $js-button-disabled-background-color;

$js-button-info-background-color: $primary-color;
$js-button-buy-background-color: $js-buy-trail-color;

$js-button-to-checkout-background-color: $js-header-background-color;
$js-button-to-checkout-background-color-hover: lighten($primary-color, 35%);

$js-button-rightarea-font-size: 13px;
$js-button-rightarea-color: $primary-color;
$js-button-rightarea-background-color: white;
$js-button-rightarea-background-color-hover: $primary-color;
$js-button-rightarea-border-color: $primary-color;
$js-button-rightarea-border-width: 1px;
$js-button-rightarea-border-padding: 4px 10px;


////////////////////////////////////////////////////////////////////////////////////////////////////////////
//
//  Product wrapper & images
//
$js-product-wrapper-width: 24%;
$js-product-wrapper-margin: 0 1% 20px 0;
$js-product-wrapper-padding: 0; // 10px
$js-product-wrapper-image-wrapper-height: 100%; // <- This is the magical setting for grid-proportions
$js-product-wrapper-image-margin: 0 0 10px;

//
// Product grid settings
//
$js-product-list-background: #fbfbfb;
$js-product-list-h3-font-size: 15px;
$js-product-list-h3-color: #333333;
$js-product-list-h3-font-weight: normal;
$js-product-list-subname-font-size: 13px;
$js-product-list-subname-color: $festool-green;
$js-product-list-articlenumber-color: black;
$js-product-list-articlenumber-font-size: 13px;
$js-product-list-price-font-size: 18px;
$js-product-list-price-previous-font-size: 14px;
$js-product-list-price-font-family: $body-font-family;
$js-product-list-description-font-size: 13px;

$js-total-products-small: 2;
$js-total-products-medium: 3;
$js-total-products-large: 4;
$js-total-products-xlarge: 4;
$js-total-products-xxlarge: 4;
$js-product-wrapper-margin-right: 2; // Int (represents percentage)
$js-product-wrapper-margin-right-large-up: 3; // Int (represents percentage)
$js-product-wrapper-selector: ".category-page-wrapper ul li";

$js-releware-wrapper-image-wrapper-height: 90%;  // <- This is the magical setting for Releware's grid-proportions
$js-releware-wrapper-selector: ".releware-recommendation-wrapper ul li";

$js-startpage-wrapper-image-wrapper-height: 100%;

//
//  Paging Control
//
$js-paging-control-box-width: 100%; // 530px
$js-paging-control-box-background-color: transparent;
$js-paging-control-box-border: none;
$js-paging-control-link-font-family:$button-font-family;
$js-paging-control-link-color: #333;
$js-paging-control-link-font-size: 12px;
$js-paging-control-link-border: 1px solid #dfdfdf;
$js-paging-control-link-border-radius: 0;
$js-paging-control-link-background-color: #efefef;
$js-paging-control-link-selected-color: black;
$js-paging-control-link-selected-background-color: #dadada;
$js-paging-control-link-selected-border: 1px solid #cdcdcd;
$js-paging-control-showall-link-color: $js-paging-control-link-color;
$js-paging-control-link-hover-color: darken($js-paging-control-link-color, 15%);
$js-paging-control-link-hover-border: 1px solid #cdcdcd;
$js-paging-control-link-hover-background-color: darken($js-paging-control-link-background-color, 15%);
$js-paging-control-link-next-prev-min-width: 100px;

//
//  Filter Control
//
$js-filter-control-background-color: $primary-color;
$js-filter-control-font-color: red;

//
//  Sorting Control
//
$js-sorting-control-background-color: $primary-color;
$js-sorting-control-selected-font-color: red;

//
//  Search
//
$js-search-box-wrapper-padding: 7px;
$js-search-box-wrapper-margin: 24px 0 0 0;
$js-search-box-wrapper-background-color: transparent;
$js-search-box-wrapper-max-height: 55px;
$js-search-box-input-width: 200px;
$js-search-box-input-padding: 7px 29px 6px 12px;
$js-search-box-input-font-size: 0.9em;
$js-search-box-input-background-color: lighten($js-header-background-color,10%);
$js-search-box-input-background-color-focus: $white;
$js-search-box-input-background-position: 6px 0;
$js-search-box-input-color: #555555;

//  Size: Small
$js-search-box-wrapper-small-padding: 10px $js-page-padding-right 10px $js-page-padding-left;
$js-search-box-wrapper-small-margin: 0;
$js-search-box-wrapper-small-background-color: #eeeeee;
$js-search-box-input-small-width: 100%;
$js-search-box-input-small-padding: 6px 12px;
$js-search-box-input-small-font-size: 16px;  // Must be 16px or above to avoid zooming-bugs in iOS
$js-search-box-input-small-background-color: white;
$js-search-box-input-small-background-position: 6px 2px;

//  Size: Medium
$js-search-box-wrapper-medium-padding: 0;
$js-search-box-wrapper-medium-margin: 0;
$js-search-box-wrapper-medium-background-color: transparent;
$js-search-box-input-medium-width: 100%;
$js-search-box-input-medium-padding: 6px 12px;
$js-search-box-input-medium-font-size: 16px;   // Must be 16px or above to avoid zooming-bugs in iOS
$js-search-box-input-medium-background-color: white;
$js-search-box-input-medium-background-position: 6px 2px;

//
//  Cart
//
$js-cart-area-wrapper-min-width: 180px;
$js-cart-area-wrapper-max-width: 400px;
$js-cart-area-wrapper-margin: 25px 20px 0 10px;
$js-cart-area-wrapper-background-color: transparent;
$js-cart-area-wrapper-background-hover-color: lighten($primary-color, 35%);
$js-cart-area-wrapper-border: none;
$js-cart-area-wrapper-border-radius: 5px;
$js-cart-area-wrapper-min-height: 0px;

$js-small-cart-color: $js-body-font-color;
$js-small-cart-height: 42px;
$js-small-cart-font-size: 18px;
$js-small-cart-border: none;
$js-small-cart-padding-right: 10px;
$js-small-cart-padding-left: 16px;
$js-small-cart-icon-size: 18px;
$js-small-cart-icon-margin-right: 5px;
$js-small-cart-items-value-margin-right: 12px;

// Size: Small
$js-cart-area-wrapper-small-min-width: 100%;
$js-cart-area-wrapper-small-max-width: 100%;
$js-cart-area-wrapper-small-margin: 0;
$js-cart-area-wrapper-small-background-color: #eeeeee;
$js-cart-area-wrapper-small-border: none;
$js-cart-area-wrapper-small-border-radius: 0;
// Size: medium
$js-cart-area-wrapper-medium-min-width: 100%;
$js-cart-area-wrapper-medium-max-width: 100%;
$js-cart-area-wrapper-medium-margin: 0;
$js-cart-area-wrapper-medium-background-color: #eeeeee;
$js-cart-area-wrapper-medium-border: none;
$js-cart-area-wrapper-medium-border-radius: 0;
// Free Freight
$js-cart-freefreight-background: transparent;
$js-cart-freefreight-border-top: 1px solid #dfdfdf;
//$js-cart-freefreight-reached-background: #DCFFDC;
$js-cart-freefreight-reached-background: transparent;
$js-cart-freefreight-reached-border-top: 1px solid #dfdfdf;

//
//  Free Freight Message
//
$js-freefreight-background: #FFC000 url(/stage/images/responsive-base/opacity15-black-bg.png) bottom left repeat-x;
$js-freefreight-font-color: white;
$js-freefreight-border-radius: 0;
$js-freefreight-reached-background: #8db451 url(/stage/images/responsive-base/opacity15-black-bg.png) bottom left repeat-x;
$js-freefreight-reached-font-color: white;

//
//  Menu
//
//TODO move this to module mobile-meny
$js-menu-item-height: 40px;
$js-menu-item-color: #fff;
$js-menu-buttons-wrapper-background-color: $primary-color;
$js-menu-buttons-wrapper-background-hover-color: darken($primary-color, 10%);
$js-menu-buttons-font-family: $font-family-header;
$js-menu-text-transform: uppercase;
$js-menu-buttons-font-size: 18px;
$js-menu-buttons-medium-icon-size: 22px;
$js-menu-buttons-small-icon-size: 22px;
$js-menu-buttons-padding: 0px 12px 0px 8px;

//
//  Culture & Currency selector
//
$js-selector-background-color: grey-scale(15);
$js-selector-color: white;
$js-selector-border-radius: 5px;

//
//  Responsive checkout
//
$js-checkout-max-width: 900px;
$js-checkout-header-number-color: $primary-color;
$js-checkout-checkout-background-color: $js-buy-trail-color;
$js-checkout-form-button-factor: 10%;
$js-checkout-selected-color: #e8ffbb;
$js-checkout-country-selector-item-active-background-color: $js-checkout-selected-color;
$js-checkout-country-selector-item-active-color: $js-body-font-color;
$js-checkout-payment-selector-item-active-background-color: $js-checkout-selected-color;
$js-checkout-payment-selector-item-active-color: $js-body-font-color;
$js-checkout-freight-selector-item-active-background-color: $js-checkout-selected-color;
$js-checkout-freight-selector-item-active-color: $js-body-font-color;
$js-checkout-freefreight-background-color: red;
$js-checkout-freefreight-reached-background-color: #80ff80;

//
//  Left Category Navigation (_left-category-menu.scss) (OBSOLETE??)
//
$js-leftnav-link-color: $js-primary-link-color;
$js-leftnav-link-hover-color: $js-primary-link-hover-color;
$js-leftnav-link-lv2-color: $js-primary-link-color;
$js-leftnav-link-lv2-hover-color: $js-primary-link-hover-color;

//
//  Breadcrumbs
//
$js-breadcrumb-font-size: 13px;
$js-breadcrumb-font-color: $js-primary-link-color;

//
//  Widget Boxes
//
$js-widget-box-header-background-color: #f9f9f9;
$js-widget-box-background-color: #f9f9f9;

//
//  Start Page Objects
//
$js-startpage-objects-price-height: 41px;
$js-startpage-objects-background-color: white;

//
//  Product Page
//
$js-product-page-price-font-size: 2.2em;
$js-product-page-previous-price-font-size: 1em;

//
//  Footer
//
$js-footer-color: #b2b2b2;
$js-footer-background-color: #222222;
$js-footer-border-top-color: darken($js-footer-background-color,10%);

//
//  Define Media Queries
//
//
//
//  iPhone5 portrait   320px
//  iPhone6 portrait   375px
//  iPhone5 landscape  568px
//  iPhone6 landscape  667px
//  iPad portrait      768px
//  iPad landscape     1024px
//  MacBook Air        1366px
//  Monitor            1920px
//
//  1366x768	16:9	19.1%	14'' Notebook / 15.6'' Laptop / 18.5'' monitor
//  1920x1080	16:9	9.4%	21.5'' monitor / 23'' monitor / 1080p TV
//  1280x800	8:5	    8.5%	14'' Notebook
//  320x568	    9:16	6.4%	4'' iPhone 5
//  1440x900	8:5	    5.7%	19'' monitor
//  1280x1024	5:4	    5.5%	19'' monitor
//  320x480	    2:3	    5.2%	3.5'' iPhone
//  1600x900	16:9	4.6%	20'' monitor
//  768x1024	3:4	    4.5%	9.7'' iPad / 15'' monitor
//
//  Original (Base 16):
//  40em = 640px    Small
//  64em = 1024px   Medium
//  90em = 1440px   Large
//  120em = 1920px  Xlarge
//
//  Solution:
//  40em > 640px    Small  = 640px  40em (mobile Button-menu)  iPhone portrait
//  64em > 1024px   Medium = 1008px 63em  (mobile Button-menu) iPhone landscape
//  90em > 1440px   Large  = 1360px 85em  laptop + monitor
//  120em > 1920px  Xlarge = 1600px 100em
//  120.063 ->      XXlarge
//


// These sizes must be updated in _global.scss
$small-breakpoint: em-calc(640);
$medium-breakpoint: em-calc(1008);
$large-breakpoint: em-calc(1360);
$xlarge-breakpoint: em-calc(1600);

$small-range:   (0, $small-breakpoint);
$medium-range:  ($small-breakpoint  + em-calc(1), $medium-breakpoint);
$large-range:   ($medium-breakpoint + em-calc(1), $large-breakpoint);
$xlarge-range:  ($large-breakpoint  + em-calc(1), $xlarge-breakpoint);
$xxlarge-range: ($xlarge-breakpoint + em-calc(1), em-calc(99999999));

$screen: "only screen";

$landscape: "#{$screen} and (orientation: landscape)";
$portrait: "#{$screen} and (orientation: portrait)";

$small-up: $screen;
$small-only: "#{$screen} and (max-width: #{upper-bound($small-range)})";

$medium-up: "#{$screen} and (min-width:#{lower-bound($medium-range)})";
$medium-only: "#{$screen} and (min-width:#{lower-bound($medium-range)}) and (max-width:#{upper-bound($medium-range)})";

$large-up: "#{$screen} and (min-width:#{lower-bound($large-range)})";
$large-only: "#{$screen} and (min-width:#{lower-bound($large-range)}) and (max-width:#{upper-bound($large-range)})";

$xlarge-up: "#{$screen} and (min-width:#{lower-bound($xlarge-range)})";
$xlarge-only: "#{$screen} and (min-width:#{lower-bound($xlarge-range)}) and (max-width:#{upper-bound($xlarge-range)})";

$xxlarge-up: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)})";
$xxlarge-only: "#{$screen} and (min-width:#{lower-bound($xxlarge-range)}) and (max-width:#{upper-bound($xxlarge-range)})";

$retina: (
  "#{$screen} and (-webkit-min-device-pixel-ratio: 2)",
  "#{$screen} and (min--moz-device-pixel-ratio: 2)",
  "#{$screen} and (-o-min-device-pixel-ratio: 2/1)",
  "#{$screen} and (min-device-pixel-ratio: 2)",
  "#{$screen} and (min-resolution: 192dpi)",
  "#{$screen} and (min-resolution: 2dppx)"
);

$medium-down: "#{$screen} and (min-width:#{lower-bound($small-range)}) and (max-width:#{upper-bound($medium-range)})";
