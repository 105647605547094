
/* FREE FREIGHT
----------------------------------------------------------------------------- */
#FreeFreight_LeftArea{
  display: none;
}

.FreeFreight_LeftArea, .FreeFreight_RightArea {
  background: $js-freefreight-background;
  color: $js-freefreight-font-color;
  cursor: pointer;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  margin: 0 0 20px 0;
  @include border-radius($js-freefreight-border-radius);
  behavior: url(/SystemScripts/PIE.htc);
}

.FreeFreight_LeftArea_reached, .FreeFreight_RightArea_reached {
  background: $js-freefreight-reached-background;
  color: $js-freefreight-reached-font-color;
  cursor: default;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  margin: 0 0 20px 0;
  @include border-radius($js-freefreight-border-radius);
  behavior: url(/SystemScripts/PIE.htc);
}

.FreeFreight_CheckoutCart {
  display: table;
  background: $js-freefreight-background;
  color: $js-freefreight-font-color;
  cursor: default;
  padding: 10px;
  height: 50px;
  line-height: 25px;
  text-align: center;
  font-weight: bold;
  margin-top: 40px;
  margin-left: 40px;
  width: 315px;
  float: left;
  @include border-radius($js-freefreight-border-radius);
  behavior: url(/SystemScripts/PIE.htc);
  margin-bottom: 75px;
}

.FreeFreight_CheckoutCart_reached {
  display: table;
  background: $js-freefreight-reached-background;
  color: $js-freefreight-reached-font-color;
  cursor: default;
  padding: 10px;
  height: 50px;
  line-height: 25px;
  text-align: center;
  font-weight: bold;
  margin-top: 40px;
  margin-left: 40px;
  width: 315px;
  float: left;
  @include border-radius($js-freefreight-border-radius);
  behavior: url(/SystemScripts/PIE.htc);
  margin-bottom: 75px;
}

.FreeFreight_CheckoutConfirm {
  background: $js-freefreight-background;
  color: $js-freefreight-font-color;
  float: right;
  width: 277px;
  cursor: default;
  height: auto;
  line-height: 25px;
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
  @include border-radius($js-freefreight-border-radius);
  behavior: url(/SystemScripts/PIE.htc);
}

.FreeFreight_CheckoutConfirm_reached {
  background: $js-freefreight-reached-background;
  color: $js-freefreight-reached-font-color;
  float: right;
  width: 277px;
  cursor: default;
  height: auto;
  line-height: 25px;
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
  @include border-radius($js-freefreight-border-radius);
  behavior: url(/SystemScripts/PIE.htc);
}

.freeFreightExpand {
  text-align: center;
  display: none;
  padding-top: 10px;
}