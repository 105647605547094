//
//  Newsletter Box
//
.newsletter-box-wrapper {
  max-width: 100%;
  margin: 0 auto;
}

.newsletter-box-header {
  background: none;
  border: 0;
  padding: 0;
  margin-bottom: 15px;
  span {
    // Make this behave like a h2
    font-size: $h4-font-size;
    text-transform: $font-transform-header;
    color: $footer-color;
    font-family: $header-font-family;
    font-style: $header-font-style;
    font-weight: $header-font-weight;
    line-height: $header-line-height;
    margin-bottom: 0;
    margin-top: 0;
    text-rendering: $header-text-rendering;
  }
}

// Add the envelope icon after newsletter header
.newsletter-box-header span:after {
  font-family: FontAwesome;
  content: "\f003";
  padding-left: 6px;
}

.newsletter-box-body {
  max-width: 500px;
  background: none;
  border: 0;
  padding: 0;
}

.newsletter-box-body:hover {
  background: none;
}

.newsletter-box-text {
  display: none;
}
.newsletter-box-radio{

}
.newsletter-box-radio-subscribe {
  float: left;
  margin: 0 10px 5px 0;

  label {
    display: inline-block;
    margin-left: 6px;
  }
}

.newsletter-box-radio-unsubscribe {
  float: left;
  margin: 0 0 5px 0;

  label {
    display: inline-block;
    margin-left: 6px;
  }
}

.newsletter-box-input {
  float: left;
  clear: both;
  width: 60%;
  margin: 0;
  max-width: none;
}

.newsletter-box-input input {
  border: 1px solid #cdcdcd;
  color: $js-footer-color;
  width: 100%;
  height: 35px;
  line-height: 35px;
  background-color: $js-footer-background-color;
  padding: 0 5px 0 10px;
  font-size: 0.9em;
  max-width: none;
}

.newsletter-box-button {
  //float: right;
  margin-left: 15px;
  width: 18%;
  a {
    padding: 0;
    height: 35px;
    line-height: 35px;
    color: $js-button-color !important;
    background: $js-buy-trail-color !important;
  }
}

.newsletter-box-footer {
  background: none;
  margin: 0;
}

